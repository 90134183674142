import { Alert, AlertIcon, Box, Center, Stack } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Card } from "../../components/Cards/BasicCard";
import { Logo } from "../../components/Common/Logo";
import ResetPasswordForm from "../../components/Forms/ResetPasswordForm";
import useMutationHandler from "../../hooks/useMutationHandler";
import useQueryHandler from "../../hooks/useQueryHandler";
import { meApi, resetPasswordApi } from "../../services/user";
import { RQ_USER_KEY } from "../../utils/constants";
import { genericErrorMsg } from "../../utils/helper";

const ForgetPassword = (props) => {
  let history = useHistory();
  let location = useLocation();
  const [errorMessage, setErrorMessage] = useState("");

  const { mutateAsync, isLoading } = useMutationHandler(resetPasswordApi, []);
  const {
    data,
    error,
    isLoading: isLoadingQuery,
  } = useQueryHandler(RQ_USER_KEY, meApi);

  useEffect(() => {
    if (!isLoadingQuery) {
      if (!error && data) {
        history.replace("/dashboard/home");
      }
    }
  }, [data, error, isLoadingQuery, history]);

  const handleSubmit = async (values) => {
    try {
      setErrorMessage("");
      if (!props.match.params || !props.match.params.token) {
        setErrorMessage("Token is missing");
      }
      const data = { ...values, token: props.match.params.token };
      delete data.confirmPassword;
      await mutateAsync(data, {
        onSuccess: () => {
          let { from } = location.state || {
            from: { pathname: "/dashboard/home" },
          };
          history.replace(from);
        },
        onError: (err) => {
          throw err;
        },
      });
    } catch (error) {
      const errorMsg = genericErrorMsg(error);
      setErrorMessage(errorMsg);
    }
  };

  const initialValues = {
    password: "",
    confirmPassword: "",
  };

  return (
    <Box
      as="section"
      bgGradient={{ md: "linear(to-r, blue.600, purple.600)" }}
      minH="100vh"
      py="20"
    >
      <Card maxW="2xl" mx="auto" textAlign="center">
        <Stack maxW="lg" mx="auto" spacing="8">
          <Center>
            <Logo boxSize={{ base: "8rem", md: "10rem" }} />
          </Center>
          {errorMessage && (
            <Alert status="error">
              <AlertIcon />
              {errorMessage}
            </Alert>
          )}
          <ResetPasswordForm
            isLoading={isLoading}
            handleSubmit={handleSubmit}
            initialValues={initialValues}
          />
        </Stack>
      </Card>
    </Box>
  );
};

export default ForgetPassword;
