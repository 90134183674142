import { Flex, Text } from "@chakra-ui/react";
import { NoDataFoundIllustration } from "../Illustration/NoDataFoundIllustration";
import { SearchIllustration } from "../Illustration/SearchIllustration";

export const SocialMediaEmptyHandler = ({ isSearched }) => {
  return (
    <Flex justify="center" align="center" direction="column" w="full" h="60vh">
      {isSearched ? <NoDataFoundIllustration /> : <SearchIllustration />}
      <Text
        ml={{ base: "-20", "2xl": "-40" }}
        mt={{ base: "-10", "2xl": "-20" }}
        fontSize="2rem"
        color="primary"
        fontWeight="extrabold"
      >
        {isSearched && "No Result found"}
      </Text>
    </Flex>
  );
};
