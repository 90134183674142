import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  Input,
} from "@chakra-ui/react";
import { Field } from "formik";

const EditInput = (props) => {
  const { label, value, name, isRequired, placeholder, ...flexProps } = props;

  return (
    <Flex
      as="dl"
      direction={{
        base: "column",
        sm: "row",
      }}
      px="6"
      py="4"
      _even={{
        bg: "gray.50",
      }}
      alignItems="center"
      {...flexProps}
    >
      <Box as="dt" minWidth="180px">
        {label}
      </Box>
      <Field name={name}>
        {({ field, form }) => (
          <FormControl isInvalid={form.errors[name] && form.touched[name]}>
            <Input
              {...field}
              id={name}
              placeholder={placeholder}
              isRequired={isRequired}
              {...flexProps}
              size="sm"
              borderRadius="md"
            />
            <FormErrorMessage>{form.errors[name]}</FormErrorMessage>
          </FormControl>
        )}
      </Field>
    </Flex>
  );
};

export default EditInput;
