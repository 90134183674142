import ComingSoon from '../../../components/Common/ComingSoon';
import DashboardLayout from '../../../components/Layouts/DashboardLayout';

const Index = () => {
  return (
    <DashboardLayout>
      <ComingSoon />
    </DashboardLayout>
  );
};

export default Index;
