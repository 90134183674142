import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
} from '@chakra-ui/react';
import { HiChevronRight } from 'react-icons/hi';
import { Link } from 'react-router-dom';

export const NavBreadcrumb = (props) => (
  <Breadcrumb
    fontSize="sm"
    {...props}
    separator={
      <Box
        as={HiChevronRight}
        color="gray.400"
        fontSize="md"
        top="2px"
        pos="relative"
      />
    }
  >
    {props.currentlinklocationdata.map((data, index) => (
      <BreadcrumbItem color="inherit" key={index}>
        <BreadcrumbLink fontSize="lg" as={Link} to={data.to} fontWeight="semibold" color="primary">
          {data.label}
        </BreadcrumbLink>
      </BreadcrumbItem>
    ))}
  </Breadcrumb>
);
