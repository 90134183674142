import { useMemo } from "react";
import { NavBreadcrumb } from "../../../components/Common/Breadcrumb";
import DashboardLayout from "../../../components/Layouts/DashboardLayout";
import MovieDetail from "../../../components/MoviePage/MovieDetail";
import SubtitleSection from "../../../components/MoviePage/SubtitleSection";
import useQueryHandler from "../../../hooks/useQueryHandler";
import { getSingleS3MovieApi } from "../../../services/s3movies";
import { RQ_SINGLE_MOVIE_KEY } from "../../../utils/constants";

const Index = (props) => {
  const movie_name = props.match.params.movieName;
  const movie_id = props.match.params.movieId;

  const { data } = useQueryHandler(RQ_SINGLE_MOVIE_KEY, () =>
    getSingleS3MovieApi(movie_id)
  );

  const currentlinklocationdata = useMemo(
    () => [
      {
        label: "Dashboard",
        isCurrentPage: false,
        canNavigate: false,
        to: "/dashboard/home",
      },
      {
        label: "Qualtity Control Pool",
        isCurrentPage: true,
        canNavigate: true,
        to: "/dashboard/quality-control-pool",
      },
      {
        label: "Movies",
        isCurrentPage: true,
        canNavigate: true,
        to: "/dashboard/quality-control-pool",
      },
      {
        label: (data && data.data.movie_file_name) || movie_name,
        isCurrentPage: true,
        canNavigate: true,
        to: `/dashboard/quality-control-pool/movies/${movie_id}/${
          (data && data.data.movie_file_name) || movie_name
        }`,
      },
    ],
    [data, movie_id, movie_name]
  );

  return (
    <DashboardLayout>
      <NavBreadcrumb
        currentlinklocationdata={currentlinklocationdata}
      ></NavBreadcrumb>
      <MovieDetail props={props} />
      <SubtitleSection props={props} />
    </DashboardLayout>
  );
};

export default Index;
