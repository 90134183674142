import { HStack } from "@chakra-ui/react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { CustomNumberInput } from "../FormControls/CustomNumberInput";
import { CustomRadio } from "../FormControls/CustomRadio";
import { CustomTextArea } from "../FormControls/CustomTextArea";

const SubtileSchema = Yup.object().shape({
  text: Yup.string().required("Required"),
  start: Yup.string().required("Required"),
  end: Yup.string().required("Required"),
  key: Yup.string().required("Required"),
});

const SubtitleForm = ({
  initialValues,
  handleSubmit,
  handleSubtitleInfoUpdate,
}) => {
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={SubtileSchema}
      enableReinitialize={true}
    >
      {(props) => (
        <Form>
          <CustomTextArea
            placeholder="Enter subtitle text"
            label="Subtitle text"
            name="text"
            isRequired={true}
            onKeyUp={() => {
              if (!props.errors.text) {
                const value = props.values.text;
                const data = {
                  ...props.values,
                  text: value,
                };
                handleSubmit(data);
              }
            }}
            bg="white"
          />
          <HStack mb="4">
            <CustomNumberInput
              placeholder="Enter start time"
              label="Start Time"
              name="start"
              isRequired={true}
              onKeyUp={() => {
                if (!props.errors.start) {
                  const value = props.values.start;
                  const data = {
                    ...props.values,
                    start: value,
                  };
                  handleSubmit(data);
                }
              }}
              bg="white"
            />
            <CustomNumberInput
              placeholder="Enter end time"
              label="End time"
              name="end"
              isRequired={true}
              onKeyUp={() => {
                if (!props.errors.end) {
                  const value = props.values.end;
                  const data = {
                    ...props.values,
                    end: value,
                  };
                  handleSubmit(data);
                }
              }}
              bg="white"
            />
          </HStack>

          <CustomRadio
            isRequired={true}
            label="Subtitle Info"
            name="key"
            options={[
              { value: "useful", key: "Useful" },
              { value: "not_audible", key: "Not Audible" },
              { value: "useless", key: "Useless" },
            ]}
            onClick={(event) => {
              if (!props.errors.key) {
                const data = {
                  ...props.values,
                  key: event.target.value,
                };
                handleSubtitleInfoUpdate(data);
              }
            }}
          />
        </Form>
      )}
    </Formik>
  );
};

export default SubtitleForm;
