export const genericErrorMsg = (error) => {
  return (
    error?.response?.data?.message ||
    error?.response?.message ||
    error?.message ||
    "Something went wrong. The error has already been reported and we are working on fixing it."
  );
};

export const getDateInMonthDayYear = (date) => {
  const d = new Date(date);
  const options = { year: "numeric", month: "long", day: "numeric" };
  const n = d.toLocaleDateString("en-US", options);
  const replase = n.replace(new RegExp(",", "g"), " ");
  return replase;
};

export const epochToHumanDateFormat = (epoch) => {
  if (epoch) {
    if (!Number.isSafeInteger(epoch)) {
      epoch = epoch * 1000;
    }
    const dateObject = new Date(epoch);
    const humanDateFormat = `${dateObject.toLocaleTimeString()} [${dateObject.toLocaleDateString()}]`;
    return humanDateFormat;
  } else {
    return "NA";
  }
};

export const isAllowed = (dashboardAccess, userAccess) => {
  if (dashboardAccess?.length > 0 && userAccess?.length > 0) {
    const exists = dashboardAccess?.some((access) =>
      userAccess.includes(access)
    );
    return exists;
  } else {
    return false;
  }
};
