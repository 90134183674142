import { Box, Flex, Heading, useToast } from "@chakra-ui/react";
import { useRef, useState } from "react";
import { useAsyncDebounce } from "react-table";
import { columns as MovieStatusColumns } from "../../datatables/movieStatus";
import useMutationHandler from "../../hooks/useMutationHandler";
import usePaginationReducer from "../../hooks/usePaginationReducer";
import useQueryHandler from "../../hooks/useQueryHandler";
import {
  getS3MoviesApi,
  searchS3MoviesApi,
  updates3MovieStatus,
} from "../../services/s3movies";
import queryClient from "../../utils/client";
import {
  RQ_MOVIE_STATUS_TABLE_KEY,
  RQ_MOVIE_STATUS_TABLE_SEARCH_KEY,
} from "../../utils/constants";
import { genericErrorMsg } from "../../utils/helper";
import ErrorBox from "../Common/ErrorBox";
import { CustomTable } from "../Table/CustomTable";

const MovieStatusTable = () => {
  const toast = useToast();

  const toastIdRef = useRef();

  const [searchValue, setSearchValue] = useState("");
  const [showSearchData, setShowSearchData] = useState(false);

  const {
    page: s3MovieStatusPage,
    limit: s3MovieStatusLimit,
    dispatch: s3MovieStatusNotSearchDispatch,
  } = usePaginationReducer();

  const {
    page: s3SearchMoviePage,
    limit: s3SearchLimit,
    dispatch: s3SearchMovieDispatch,
  } = usePaginationReducer();

  const {
    data: s3Movie,
    isLoading: s3MovieWithoutSearchIsLoading,
    isError: isS3MovieError,
    error: s3MovieErrorMessage,
  } = useQueryHandler(
    [RQ_MOVIE_STATUS_TABLE_KEY, s3MovieStatusPage, s3MovieStatusLimit],
    () => getS3MoviesApi(s3MovieStatusPage, s3MovieStatusLimit),
    {
      keepPreviousData: true,
      staleTime: Infinity,
    }
  );

  const {
    data: s3SearchMovie,
    isLoading: s3MovieSearchIsLoading,
    isError: isS3SearchMovieError,
    error: s3MovieSearchErrorMessage,
  } = useQueryHandler(
    [
      RQ_MOVIE_STATUS_TABLE_SEARCH_KEY,
      searchValue,
      s3SearchMoviePage,
      s3SearchLimit,
    ],
    () => searchS3MoviesApi(searchValue, s3SearchMoviePage, s3SearchLimit)
  );

  const { mutateAsync: mutateAsyncMovieStatus } = useMutationHandler(
    updates3MovieStatus,
    [RQ_MOVIE_STATUS_TABLE_KEY, RQ_MOVIE_STATUS_TABLE_SEARCH_KEY]
  );

  const onS3MovieSearch = useAsyncDebounce(async (value) => {
    if (value) {
      setShowSearchData(true);
      setSearchValue(value);
      queryClient.invalidateQueries([
        RQ_MOVIE_STATUS_TABLE_SEARCH_KEY,
        searchValue,
        s3SearchMoviePage,
        s3SearchLimit,
      ]);
    } else if (!value) {
      setShowSearchData(false);
    }
  }, 150);

  const s3MovieData = showSearchData ? s3SearchMovie : s3Movie;
  const s3MovieIsLoading = showSearchData
    ? s3MovieSearchIsLoading
    : s3MovieWithoutSearchIsLoading;
  const s3MovieError = showSearchData ? isS3SearchMovieError : isS3MovieError;
  const s3MovieDispatch = showSearchData
    ? s3SearchMovieDispatch
    : s3MovieStatusNotSearchDispatch;
  const s3CurrentPage = showSearchData ? s3SearchMoviePage : s3MovieStatusPage;
  const s3CurrentPageLimit = showSearchData
    ? s3SearchLimit
    : s3MovieStatusLimit;
  const s3ErrorMessage = showSearchData
    ? s3MovieSearchErrorMessage
    : s3MovieErrorMessage;

  const mutateMovieQcStatus = async ({ id, type, status }) => {
    await mutateAsyncMovieStatus(
      { id, body: { type, status } },
      {
        onError: (error) => {
          const errorMessage = genericErrorMsg(error);
          if (toastIdRef.current) {
            toast.close(toastIdRef.current);
          }
          toastIdRef.current = toast({
            title: "Error Occured.",
            description: errorMessage,
            status: "error",
            duration: 3000,
            isClosable: true,
            position: "top",
          });
        },
      }
    );
  };

  const mutateMovieAnnotateStatus = async ({ id, type, status }) => {
    await mutateAsyncMovieStatus(
      { id, body: { type, status } },
      {
        onError: (error) => {
          const errorMessage = genericErrorMsg(error);
          if (toastIdRef.current) {
            toast.close(toastIdRef.current);
          }
          toastIdRef.current = toast({
            title: "Error Occured.",
            description: errorMessage,
            status: "error",
            duration: 3000,
            isClosable: true,
            position: "top",
          });
        },
      }
    );
  };

  return (
    <Box overflowX="auto" mt="5" borderWidth="1px" borderRadius="lg" p="5">
      <Flex
        direction={{
          base: "column",
          lg: "row",
        }}
        mb={{
          base: "5",
          lg: "0",
        }}
        justifyContent="space-between"
      >
        <Heading size="lg" mb="6" color="primary">
          Movies Status
        </Heading>
      </Flex>
      {!s3MovieError && (
        <CustomTable
          isLoading={s3MovieIsLoading}
          tableData={s3MovieData?.data?.movies || []}
          Columns={MovieStatusColumns}
          currentPage={s3CurrentPage}
          limit={s3CurrentPageLimit}
          dispatch={s3MovieDispatch}
          totalCount={s3MovieData?.data?.total_pages || 0}
          onSearch={onS3MovieSearch}
          mutateFnObjects={{ mutateMovieQcStatus, mutateMovieAnnotateStatus }}
        />
      )}
      {s3MovieError && <ErrorBox errorMessage={s3ErrorMessage} />}
    </Box>
  );
};

export default MovieStatusTable;
