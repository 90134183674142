import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  Select,
} from "@chakra-ui/react";
import { Field } from "formik";

const EditSelect = (props) => {
  const { label, value, name, data, isRequired, placeholder, ...flexProps } =
    props;

  return (
    <Flex
      as="dl"
      direction={{
        base: "column",
        sm: "row",
      }}
      px="6"
      py="4"
      _even={{
        bg: "gray.50",
      }}
      {...flexProps}
 
      alignItems="center"
    >
      <Box as="dt" minWidth="180px">
        {label}
      </Box>
      <Field name={name}>
        {({ field, form }) => (
          <FormControl isInvalid={form.errors[name] && form.touched[name]}>
            <Select
              isRequired={isRequired}
              placeholder={placeholder}
              {...field}
              size="sm"
              borderRadius="md"
            >
              {data &&
                data?.length > 0 &&
                data.map((e, i) => (
                  <option
                    key={i}
                    value={e.name}
                    style={{ backgroundColor: "#373838", color: "white" }}
                  >
                    {e.name}
                  </option>
                ))}
            </Select>
            <FormErrorMessage>{form.errors[name]}</FormErrorMessage>
          </FormControl>
        )}
      </Field>
    </Flex>
  );
};

export default EditSelect;
