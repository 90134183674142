import { Flex, Text } from '@chakra-ui/react';
import { ComingSoonIllustration } from '../Illustration/ComingSoonIllustration';

const ComingSoon = () => {
  return (
    <Flex justify="center" align="center" direction="column" w="full" h="80vh">
      <ComingSoonIllustration />
      <Text mt="-4" fontSize="2rem" color="primary" fontWeight="extrabold">
        This page is still under construction
      </Text>
    </Flex>
  );
};

export default ComingSoon;
