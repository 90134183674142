import {
  ArrowBackIcon,
  ArrowForwardIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
} from '@chakra-ui/icons';
import { Box, IconButton, Input } from '@chakra-ui/react';

export const CustomPaginationBox = ({
  previousPage,
  canPreviousPage,
  nextPage,
  canNextPage,
  gotoPage,
  pageCount,
  pageIndex,
  pageOptions,
}) => {
  return (
    <Box flexDirection="row" mt={4}>
      <IconButton
        onClick={() => gotoPage(0)}
        disabled={!canPreviousPage}
        mr={2}
        icon={<ArrowLeftIcon />}
        aria-label="ArrowLeftIcon"
      />
      <IconButton
        onClick={() => previousPage()}
        disabled={!canPreviousPage}
        mr={2}
        icon={<ArrowBackIcon />}
        aria-label="ArrowBackIcon"
      />
      <Box style={{ display: 'inline' }} mr={3}>
        <Input
          type="number"
          value={pageIndex + 1}
          max={pageOptions.length}
          min={1}
          size="sm"
          variant="filled"
          onChange={(e) => {
            const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0;
            gotoPage(pageNumber);
          }}
          mr={2}
          width="14"
        />
        of {pageOptions.length}
      </Box>
      <IconButton
        onClick={() => nextPage()}
        disabled={!canNextPage}
        icon={<ArrowForwardIcon />}
        mr={2}
        aria-label="ArrowForwardIcon"
      />
      <IconButton
        onClick={() => gotoPage(pageCount - 1)}
        disabled={!canNextPage}
        icon={<ArrowRightIcon />}
        mr={2}
        aria-label="ArrowRightIcon"
      />
    </Box>
  );
};
