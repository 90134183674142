import { Flex, Spinner } from "@chakra-ui/react";

const CustomSpinner = (props) => {
  return (
    <Flex justify="center" alignItems="center" height="80vh" {...props}>
      <Spinner size="xl" color="secondary" />
    </Flex>
  );
};

export default CustomSpinner;
