import { Alert, AlertIcon, Box, Center, Stack } from "@chakra-ui/react";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { Card } from "../../components/Cards/BasicCard";
import { Logo } from "../../components/Common/Logo";
import TelegramUpdatePasswordForm from "../../components/Forms/TelegramUpdatePasswordForm";
import useMutationHandler from "../../hooks/useMutationHandler";
import { telegramPasswordUpdateApi } from "../../services/user";
import { genericErrorMsg } from "../../utils/helper";

const TelegramAccountPasswordUpdate = (props) => {
  let history = useHistory();
  const { mutateAsync } = useMutationHandler(telegramPasswordUpdateApi);
  const [errorMessage, setErrorMessage] = useState("");

  const initialValues = {
    password: "",
    confirmPassword: "",
  };

  const handleSubmit = async (values, actions) => {
    try {
      const token = props.match.params.token;
      const newValues = {
        password: values.password,
        token,
      };
      await mutateAsync(newValues, {
        onSuccess: () => {
          history.replace("/login");
        },
        onError: (error) => {
          throw error;
        },
      });
    } catch (error) {
      const errorMessage = genericErrorMsg(error);
      setErrorMessage(errorMessage);
    } finally {
      actions.setSubmitting(false);
    }
  };

  return (
    <Box
      as="section"
      bgGradient={{ md: "linear(to-r, blue.600, purple.600)" }}
      minH="100vh"
      py="20"
    >
      <Card maxW="2xl" mx="auto" textAlign="center">
        <Stack maxW="lg" mx="auto" spacing="8">
          <Center>
            <Logo boxSize={{ base: "8rem", md: "10rem" }} />
          </Center>
          {errorMessage && (
            <Alert status="error">
              <AlertIcon />
              {errorMessage}
            </Alert>
          )}
          <TelegramUpdatePasswordForm
            handleSubmit={handleSubmit}
            initialValues={initialValues}
          />
        </Stack>
      </Card>
    </Box>
  );
};

export default TelegramAccountPasswordUpdate;
