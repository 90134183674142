export const columns = [
  {
    Header: "Movie",
    accessor: "movie_file_name",
  },
  {
    Header: "Subtitle",
    accessor: "subtitle_file_name",
    disableFilters: true,
  }
];
