import { Box, SimpleGrid } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FaUserAlt, FaUserSlash } from "react-icons/fa";
import { NavBreadcrumb } from "../../components/Common/Breadcrumb";
import { StatCard } from "../../components/Cards/StatCards";
import MovieStatusTable from "../../components/HomePage/MovieStatusTable";
import SubtitleAssignmentTable from "../../components/HomePage/SubtitleAssignmentTable";
import DashboardLayout from "../../components/Layouts/DashboardLayout";
import useQueryHandler from "../../hooks/useQueryHandler";
import { meApi } from "../../services/user";
import { RQ_USER_KEY } from "../../utils/constants";
import { isAllowed as isAllowedFn } from "../../utils/helper";

const currentlinklocationdata = [
  {
    label: "Dashboard",
    isCurrentPage: false,
    canNavigate: false,
    to: "#",
  },
  {
    label: "Home",
    isCurrentPage: true,
    canNavigate: true,
    to: "/dashboard/home",
  },
];

const statData = [
  {
    label: "Active Users",
    count: 181,
    icon: FaUserAlt,
  },
  {
    label: "In Active Users",
    count: 113,
    icon: FaUserSlash,
  },
];

const Index = () => {
  const [isAllowed, setIsAllowed] = useState(false);
  const { data, isLoading } = useQueryHandler(RQ_USER_KEY, meApi);

  useEffect(() => {
    if (data?.data && !isLoading) {
      const userAccess = data?.data?.access || [];
      const isAllowedResp = isAllowedFn(["admin", "superadmin"], userAccess);
      setIsAllowed(isAllowedResp);
    }
  }, [data, isLoading]);

  return (
    <DashboardLayout>
      {!isLoading && (
        <>
          <NavBreadcrumb currentlinklocationdata={currentlinklocationdata} />
          {isAllowed && (
            <Box mt="10" justifySelf="center" alignItems="center">
              <SimpleGrid
                columns={{
                  base: 1,
                  md: 2,
                  lg: 2,
                }}
                spacing={{
                  base: "8",
                  lg: "12",
                }}
              >
                {statData.map((data, index) => (
                  <StatCard data={data} key={index} />
                ))}
              </SimpleGrid>
              <SubtitleAssignmentTable />
              <MovieStatusTable />
            </Box>
          )}
        </>
      )}
    </DashboardLayout>
  );
};

export default Index;
