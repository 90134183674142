import Axios from "axios";
const v1Url = process.env.REACT_APP_SERVER_API_URL;
const timeoutInMilliSeconds = 100000;

export const $axiosV1 = Axios.create({
  headers: {
    "Content-Type": "application/json;charset=UTF-8",
    apiKey: process.env.REACT_APP_SERVER_API_KEY,
    client: "web",
  },
  timeout: timeoutInMilliSeconds,
  baseURL: v1Url,
  withCredentials: true,
});