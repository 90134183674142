import { Heading } from "@chakra-ui/react";
import { useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import useQueryHandler from "../../hooks/useQueryHandler";
import {
  getSubtitlesByIdApi,
  getSubtitlesBySearchAndIdApi,
} from "../../services/subtitles";
import queryClient from "../../utils/client";
import {
  RQ_SEARCH_SUBTITLES_BY_MOVIE_ID_AND_QUERY_KEY,
  RQ_SUBTITLES_BY_MOVIE_ID_KEY,
  SUBTITLE_MOVIE_PAGE_LIMIT,
} from "../../utils/constants";
import ErrorBox from "../Common/ErrorBox";
import Subtitles from "./Subtitles";

const SubtitleSection = ({ props }) => {
  const [page, setPage] = useState(0);
  const [showSearchData, setShowSearchData] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [filter, setFilter] = useState("unattended");

  const movie_id = props.match.params.movieId;
  const PAGE_LIMIT = SUBTITLE_MOVIE_PAGE_LIMIT;

  const {
    data,
    isLoading,
    isError,
    error: subtitlesErrorMessage,
  } = useQueryHandler(
    [RQ_SUBTITLES_BY_MOVIE_ID_KEY, page, PAGE_LIMIT, filter],
    () => getSubtitlesByIdApi(movie_id, page, PAGE_LIMIT, filter)
  );

  const {
    data: searchData,
    isLoading: searchIsLoading,
    isError: searchIsError,
    error: subtitlesSearchErrorMessage,
  } = useQueryHandler(
    [
      RQ_SEARCH_SUBTITLES_BY_MOVIE_ID_AND_QUERY_KEY,
      page,
      PAGE_LIMIT,
      filter,
      searchValue,
    ],
    () =>
      getSubtitlesBySearchAndIdApi(
        movie_id,
        page,
        PAGE_LIMIT,
        searchValue,
        filter
      )
  );

  const onSearchChange = useDebouncedCallback((event) => {
    const value = event.target.value;
    if (value) {
      setSearchValue(value);
      queryClient.invalidateQueries([
        RQ_SEARCH_SUBTITLES_BY_MOVIE_ID_AND_QUERY_KEY,
        page,
        18,
        searchValue,
      ]);
      setShowSearchData(true);
    } else if (!value) {
      setShowSearchData(false);
    }
  }, 150);

  const subtitleIsLoading = showSearchData ? searchIsLoading : isLoading;
  const subtitleIsError = showSearchData ? searchIsError : isError;
  const subtitleData = showSearchData ? searchData : data;
  const subtitleErrorMessage = showSearchData
    ? subtitlesSearchErrorMessage
    : subtitlesErrorMessage;

  if (subtitleIsError) {
    return (
      <>
        <Heading size="lg" mb="8" px="10" color="primary">
          All Clips:
        </Heading>
        <ErrorBox px="10" errorMessage={subtitleErrorMessage} />
      </>
    );
  }

  return (
    <Subtitles
      data={subtitleData}
      page={page}
      setPage={setPage}
      onSearchChange={onSearchChange}
      searchValue={searchValue}
      subtitleIsLoading={subtitleIsLoading}
      setFilter={setFilter}
      filter={filter}
      movie_id={movie_id}
    />
  );
};

export default SubtitleSection;
