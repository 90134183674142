import { CloseIcon } from "@chakra-ui/icons";
import { Button } from "@chakra-ui/react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { UnderlineLink } from "../components/Common/UnderlineLink";

export const columns = [
  {
    Header: "Movie Name",
    accessor: (d) =>
      d.movie_file_name && (
        <Link
          to={`/dashboard/quality-control-pool/movies/${d._id}/${d.movie_file_name}`}
        >
          <UnderlineLink fontSize="md" color="secondary" p="2">
            {d.movie_file_name}
          </UnderlineLink>
        </Link>
      ),
  },
  {
    Header: "Progress",
    accessor: (d) => d.progress && `${d.progress}%`,
    disableFilters: true,
  },
  {
    Header: "Assignee",
    accessor: "actor.qc",
    disableFilters: true,
  },
  {
    Header: "Action",
    accessor: "action",
    Cell: function (rows) {
      const [isLoading, setIsLoading] = useState(false);
      const original = rows.row.original;
      const fn = rows.mutateFnObjects.mutateMovieQcStatus;
      return (
        <Button
          isLoading={isLoading}
          onClick={async () => {
            setIsLoading(true);
            await fn({ id: original._id });
            setIsLoading(false);
          }}
          color="red"
          leftIcon={<CloseIcon />}
          variant="outline"
        >
          Deselect
        </Button>
      );
    },
  },
];
