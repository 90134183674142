import { SearchIcon } from "@chakra-ui/icons";
import { Input, InputGroup, InputLeftElement } from "@chakra-ui/react";
import { useState } from "react";

export const CustomGlobalFilter = ({ onSearch }) => {
  const [value, setValue] = useState("");

  return (
    <InputGroup mb="6">
      <InputLeftElement pointerEvents="none" children={<SearchIcon />} />
      <Input
        placeholder="Type here to search"
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
          onSearch(e.target.value);
        }}
        variant="outline"
      />
    </InputGroup>
  );
};
