import { Button, Center } from "@chakra-ui/react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import useLanguages from "../../hooks/useLanguages";
import { CustomInput } from "../FormControls/CustomInput";
import { CustomSelect } from "../FormControls/CustomSelect";

const profileUpdateSchema = Yup.object().shape({
  name: Yup.string().min("1", "Too short").required("Required"),
  nick_name: Yup.string().min("1", "Too short").required("Required"),
  email: Yup.string().email("Invalid email").required("Required"),
  support_language: Yup.string().required('Required'),
  pratice_language: Yup.string().required('Required'),
});

const ProfileUpdateForm = ({ handleSubmit, isLoading, initialValues }) => {
  const { languagesAvailable } = useLanguages();

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={profileUpdateSchema}
    >
      {(_props) => (
        <Form>
          <CustomInput
            label="Email Address"
            name="email"
            isRequired={true}
            disabled={true}
          />
          <CustomInput label="Name" name="name" isRequired={true} />
          <CustomInput label="Nick Name" name="nick_name" isRequired={true} />
          <CustomSelect
            name="support_language"
            data={languagesAvailable}
            placeholder="Select a Support language"
            isRequired={true}
            label="Support Language"
          />
          <CustomSelect
            name="pratice_language"
            data={languagesAvailable}
            placeholder="Select a Pratice language"
            isRequired={true}
            label="Pratice Language"
          />
          <Center>
            <Button
              bg="secondary"
              color="white"
              fontSize="md"
              fontWeight="bold"
              isLoading={isLoading}
              type="submit"
              mt={4}
            >
              Update
            </Button>
          </Center>
        </Form>
      )}
    </Formik>
  );
};

export default ProfileUpdateForm;
