import { Box, Button, Center, Heading, Stack, Text } from "@chakra-ui/react";
import { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { Card } from "../../components/Cards/BasicCard";
import { Logo } from "../../components/Common/Logo";
import useQueryHandler from "../../hooks/useQueryHandler";
import { meApi } from "../../services/user";
import { RQ_USER_KEY } from "../../utils/constants";

const Register = () => {
  let history = useHistory();

  const {
    data,
    error,
    isLoading: isLoadingQuery,
  } = useQueryHandler(RQ_USER_KEY, meApi);

  useEffect(() => {
    if (!isLoadingQuery) {
      if (!error && data) {
        history.replace("/dashboard/home");
      }
    }
  }, [data, error, isLoadingQuery, history]);

  return (
    <Box
      as="section"
      bgGradient={{ md: "linear(to-r, blue.600, purple.600)" }}
      minH="100vh"
      py="20"
    >
      <Card maxW="2xl" mx="auto" textAlign="center">
        <Stack maxW="lg" mx="auto" spacing="8">
          <Center>
            <Logo boxSize={{ base: "8rem", md: "10rem" }} />
          </Center>
          <Heading
            size="3xl"
            fontWeight="extrabold"
            color="primary"
            letterSpacing="tight"
          >
            Welcome to Oolingo
          </Heading>
          <Text mt="4" fontSize="lg">
            Please start with our Telegram Chat Bot by clicking on the button
            below
          </Text>
          <Button
            mt="8"
            as="a"
            href="#"
            size="lg"
            bg="primary"
            color="white"
            fontWeight="bold"
            onClick={() => {
              window.open(process.env.REACT_APP_TELEGRAM_BOT_URL);
            }}
          >
            Register today via telegram
          </Button>
          <Box fontSize="sm">
            <Text fontWeight="medium" color="gray.600">
              Already have an account?
            </Text>
            <Text fontWeight="semibold" color="primary">
              <Link to="/login">Login</Link>
            </Text>
          </Box>
        </Stack>
      </Card>
    </Box>
  );
};

export default Register;
