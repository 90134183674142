import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
} from "@chakra-ui/react";
import { Field } from "formik";

export const CustomInput = (props) => {
  const { label, name, isRequired, placeholder, ...rest } = props;
  return (
    <Field name={name}>
      {({ field, form }) => (
        <FormControl
          isRequired={isRequired}
          isInvalid={form.errors[name] && form.touched[name]}
          mb={4}
        >
          <FormLabel htmlFor={name}>{label}</FormLabel>
          <Input {...field} id={name} placeholder={placeholder} {...rest} />
          <FormErrorMessage>{form.errors[name]}</FormErrorMessage>
        </FormControl>
      )}
    </Field>
  );
};
