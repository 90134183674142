import { CheckIcon, CloseIcon } from "@chakra-ui/icons";
import { Box, ButtonGroup, Flex, IconButton, Text } from "@chakra-ui/react";
import { epochToHumanDateFormat } from "../utils/helper";
import { useState } from "react";

const CustomActionAcceptButton = ({ original, fn, type }) => {
  const [isLoading, setIsLoading] = useState(false);
  return (
    <IconButton
      onClick={async () => {
        setIsLoading(true);
        await fn({ id: original._id, type: type, status: true });
        setIsLoading(false);
      }}
      variant="outline"
      color="secondary"
      icon={<CheckIcon />}
      isLoading={isLoading}
    />
  );
};

const CustomActionRejectButton = ({ original, fn, type }) => {
  const [isLoading, setIsLoading] = useState(false);
  return (
    <IconButton
      onClick={async () => {
        setIsLoading(true);
        await fn({ id: original._id, type: type, status: false });
        setIsLoading(false);
      }}
      variant="outline"
      color="red"
      icon={<CloseIcon />}
      isLoading={isLoading}
    />
  );
};

const CustomActionButtonGroup = ({ original, fn, type }) => {
  if (type in original) {
    if (original[type]) {
      return (
        <CustomActionRejectButton original={original} fn={fn} type={type} />
      );
    } else {
      return (
        <CustomActionAcceptButton original={original} fn={fn} type={type} />
      );
    }
  } else {
    return (
      <ButtonGroup spacing="5">
        <CustomActionAcceptButton original={original} fn={fn} type={type} />
        <CustomActionRejectButton original={original} fn={fn} type={type} />
      </ButtonGroup>
    );
  }
};

const CustomTextAndDateFormat = ({ text, date }) => {
  return (
    <Flex direction="column">
      {text ? (
        <>
          <Text fontWeight="semibold">{text}</Text>
          <Text color="gray.600">{epochToHumanDateFormat(date)}</Text>
        </>
      ) : (
        <Text textAlign="center">NA</Text>
      )}
    </Flex>
  );
};

export const columns = [
  {
    Header: "Movie",
    accessor: "movie_file_name",
  },

  {
    Header: "Actor (QC)",
    accessor: "actor.qc",
  },
  {
    Header: "Actor (Annotate)",
    accessor: "actor.annotate",
  },
  {
    Header: "Updated by",
    accessor: "updated_by",
    Cell: function (rows) {
      const original = rows.row.original;
      return original?.logs?.updated_by || original?.updated_by || "NA";
    },
  },
  {
    Header: "Qc Revoked By",
    accessor: "logs.qc_revoke_by",
    Cell: function (rows) {
      const original = rows.row.original;
      return (
        <CustomTextAndDateFormat
          text={original?.logs?.qc_revoke_by}
          date={original?.logs?.qc_revoke_time || original?.logs?.revoke_time}
        />
      );
    },
  },
  {
    Header: "Qc Approved By",
    accessor: "approved_by",
    Cell: function (rows) {
      const original = rows.row.original;
      return (
        <CustomTextAndDateFormat
          text={original?.logs?.qc_approved_by || original?.logs?.approved_by}
          date={
            original?.logs?.qc_approved_time || original?.logs?.approved_time
          }
        />
      );
    },
  },
  {
    Header: "Qc Status",
    accessor: (d) =>
      d.qc_status ? (
        <Box
          bgColor="green.400"
          textAlign="center"
          color="white"
          p="2"
          borderRadius="md"
        >
          Accepted
        </Box>
      ) : d.qc_status === false ? (
        <Box
          bgColor="red.400"
          textAlign="center"
          color="white"
          p="2"
          borderRadius="md"
        >
          Rejected
        </Box>
      ) : (
        <Box
          bgColor="gray.600"
          textAlign="center"
          color="white"
          p="2"
          borderRadius="md"
        >
          Pending
        </Box>
      ),
  },
  {
    Header: "Qc Action",
    accessor: "",
    disableFilters: true,
    Cell: function (rows) {
      const original = rows.row.original;
      const fn = rows.mutateFnObjects.mutateMovieQcStatus;
      return (
        <CustomActionButtonGroup original={original} fn={fn} type="qc_status" />
      );
    },
  },
  {
    Header: "Annotate Revoked By",
    accessor: "logs.annotate_revoke_by",
    Cell: function (rows) {
      const original = rows.row.original;
      return (
        <CustomTextAndDateFormat
          text={original?.logs?.annotate_revoke_by}
          date={original?.logs?.annotate_revoke_time}
        />
      );
    },
  },
  {
    Header: "Annotate Approved By",
    accessor: "logs.annotate_approved_by",
    Cell: function (rows) {
      const original = rows.row.original;
      return (
        <CustomTextAndDateFormat
          text={original?.logs?.annotate_approved_by}
          date={original?.logs?.annotate_approved_time}
        />
      );
    },
  },
  {
    Header: "Annotate Status",
    accessor: (d) =>
      d.annotate_status ? (
        <Box
          bgColor="green.400"
          textAlign="center"
          color="white"
          p="2"
          borderRadius="md"
        >
          Accepted
        </Box>
      ) : d.annotate_status === false ? (
        <Box
          bgColor="red.400"
          textAlign="center"
          color="white"
          p="2"
          borderRadius="md"
        >
          Rejected
        </Box>
      ) : (
        <Box
          bgColor="gray.600"
          textAlign="center"
          color="white"
          p="2"
          borderRadius="md"
        >
          Pending
        </Box>
      ),
  },
  {
    Header: "Annotate Action",
    accessor: "annotate_status",
    Cell: function (rows) {
      const original = rows.row.original;
      const fn = rows.mutateFnObjects.mutateMovieAnnotateStatus;
      return (
        <CustomActionButtonGroup
          original={original}
          fn={fn}
          type="annotate_status"
        />
      );
    },
  },
  {
    Header: "Last Updated",
    accessor: "last_updated",
    Cell: function (rows) {
      const original = rows.row.original;
      return epochToHumanDateFormat(
        original?.logs?.last_updated || original?.last_updated
      );
    },
  },
];
