import { useMemo } from "react";

const useLanguages = () => {
  const languagesAvailable = useMemo(
    () => [
      { name: "English", value: "english" },
      { name: "French", value: "french" },
      { name: "Chinese", value: "chinese" },
      { name: "Malay", value: "malay" },
    ],
    []
  );

  return { languagesAvailable };
};

export default useLanguages;
