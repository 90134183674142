import { Box } from '@chakra-ui/react'
import { SocialMediaCard } from '../Cards/SocialMediaCard'
import { SocialMediaGrid } from '../Grids/SocialMediaGrid'

export const SocialMediaView = ({ data }) => (
  <Box
    mx="auto"
    px={{
      base: '4',
      md: '8',
      lg: '12',
    }}
    py={{
      base: '6',
      md: '8',
      lg: '12',
    }}
  >
    <SocialMediaGrid>
      {data.map((data) => (
        <SocialMediaCard key={data._id} singleVideo={data} />
      ))}
    </SocialMediaGrid>
  </Box>
)