import {
  AspectRatio,
  Box,
  Button,
  HStack,
  Input,
  Stack,
  Text,
  useBreakpointValue,
  useClipboard,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useRef } from "react";
import { VideoPlayer } from "../Common/VideoPlayer";

export const SocialMediaCard = (props) => {
  const { singleVideo, rootProps } = props;

  const videoBase = `${process.env.REACT_APP_S3_VIDEO_URL}/vidoe/new`;
  const url = `${videoBase}/${singleVideo?.id}_watermark.mp4?v=1`;

  const { hasCopied, onCopy } = useClipboard(url);
  const toast = useToast();
  const toastIdRef = useRef();

  useEffect(() => {
    if (hasCopied) {
      if (toastIdRef.current) {
        toast.close(toastIdRef.current);
      }
      toastIdRef.current = toast({
        title: "Video link copied.",
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
    }
  }, [hasCopied, toast]);

  return (
    <Stack
      spacing={useBreakpointValue({
        base: "4",
        md: "5",
      })}
      {...rootProps}
    >
      <Box position="relative">
        <AspectRatio ratio={16 / 9} h={{ base: "150", "2xl": "270" }} w="300">
          <VideoPlayer url={url} controls={true} />
        </AspectRatio>
      </Box>
      <Stack>
        <Stack spacing="1">
          <Text
            fontWeight="bold"
            fontSize="xl"
            color={useColorModeValue("gray.700", "gray.400")}
          >
            {singleVideo?.movie_name}
          </Text>
        </Stack>
        <HStack>
          <Text
            fontSize="sm"
            color={useColorModeValue("gray.600", "gray.400")}
            isTruncated={true}
          >
            {singleVideo.text}
          </Text>
        </HStack>
      </Stack>
      <Stack align="center">
        <Input bg="white" isDisabled value={url} mb="4" />
        <Button bg="primary" color="white" isFullWidth onClick={onCopy}>
          Copy
        </Button>
      </Stack>
    </Stack>
  );
};
