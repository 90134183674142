import {
  AspectRatio,
  Box,
  Button,
  Center,
  Spinner,
  Tag,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { AiOutlineSplitCells } from "react-icons/ai";
import { HiOutlineDotsVertical } from "react-icons/hi";
import { useDebouncedCallback } from "use-debounce";
import useMutationHandler from "../../hooks/useMutationHandler";
import {
  getSubtitlesByIdApi,
  mergeSubtitleWithNextById,
  softDelelteSubtitleById,
  splitSubtitleById,
  updateSubtitleById,
} from "../../services/subtitles";
import queryClient from "../../utils/client";
import {
  RQ_SEARCH_SUBTITLES_BY_MOVIE_ID_AND_QUERY_KEY,
  RQ_SUBTITLES_BY_MOVIE_ID_KEY,
  SUBTITLE_MOVIE_PAGE_LIMIT,
} from "../../utils/constants";
import { genericErrorMsg } from "../../utils/helper";
import { DeleteButton } from "../Buttons/DeleteButton";
import { PlayButton } from "../Buttons/PlayButton";
import { VideoPlayer } from "../Common/VideoPlayer";
import SubtitleForm from "../Forms/SubtitleForm";
import { VideoThumbnailIllustration } from "../Illustration/VideoThumbnailIllustration";

export const SubtitleCard = (props) => {
  const [videoId, setVideoId] = useState(null);
  const {
    subtitle,
    limit,
    subtitle_count,
    index,
    current_page,
    page,
    movie_id,
    filter,
  } = props;
  const toast = useToast();
  const toastIdRef = useRef();
  const videoPlayerRef = useRef();

  const [
    mergeSubtitleWithNextByIdIsLoading,
    setMergeSubtitleWithNextByIdIsLoading,
  ] = useState(false);
  const [isSubtitleInfoLoading, setIsSubtitleInfoLoading] = useState(false);

  const { mutateAsync } = useMutationHandler(updateSubtitleById, [
    RQ_SUBTITLES_BY_MOVIE_ID_KEY,
    RQ_SEARCH_SUBTITLES_BY_MOVIE_ID_AND_QUERY_KEY,
  ]);

  const {
    mutateAsync: deleteSubtitleMutateAsync,
    isLoading: deleteSubtitleIsLoading,
  } = useMutationHandler(softDelelteSubtitleById, [
    RQ_SUBTITLES_BY_MOVIE_ID_KEY,
    RQ_SEARCH_SUBTITLES_BY_MOVIE_ID_AND_QUERY_KEY,
  ]);

  const {
    mutateAsync: splitSubtitleMutateAsync,
    isLoading: splitSubtitleIsLoading,
  } = useMutationHandler(splitSubtitleById, [
    RQ_SUBTITLES_BY_MOVIE_ID_KEY,
    RQ_SEARCH_SUBTITLES_BY_MOVIE_ID_AND_QUERY_KEY,
  ]);

  const { mutateAsync: mergeSubtitleWithNextByIdMutateAsync } =
    useMutationHandler(mergeSubtitleWithNextById, [
      RQ_SUBTITLES_BY_MOVIE_ID_KEY,
      RQ_SEARCH_SUBTITLES_BY_MOVIE_ID_AND_QUERY_KEY,
    ]);

  const [cardIndex, setCardIndex] = useState(index + 1);

  const initialValues = {
    text: subtitle?.text || "",
    start: subtitle?.start || 0,
    end: subtitle?.end || 0,
    key: subtitle?.qc?.key || "",
  };

  const handleSubtitleInfoUpdate = (values) => {
    setIsSubtitleInfoLoading(true);
    mutateAsync(
      {
        subtitleId: subtitle._id,
        body: values,
      },
      {
        onError: (error) => {
          if (toastIdRef.current) {
            toast.close(toastIdRef.current);
          }
          toastIdRef.current = toast({
            title: "Error Occured.",
            description: genericErrorMsg(error),
            status: "error",
            duration: 4000,
            position: "top",
          });
        },
        onSettled: () => {
          setIsSubtitleInfoLoading(false);
        },
      }
    );
  };

  const handleSubmit = useDebouncedCallback((values) => {
    if (values.key === "") {
      delete values.key;
    }
    mutateAsync(
      {
        subtitleId: subtitle._id,
        body: values,
      },
      {
        onError: (error) => {
          if (toastIdRef.current) {
            toast.close(toastIdRef.current);
          }
          toastIdRef.current = toast({
            title: "Error Occured.",
            description: genericErrorMsg(error),
            status: "error",
            duration: 4000,
            position: "top",
          });
        },
      }
    );
  }, 1000);

  const splitHandler = async () => {
    const { _id } = subtitle;
    splitSubtitleMutateAsync(
      {
        subtitleId: _id,
      },
      {
        onSuccess: () => {
          if (toastIdRef.current) {
            toast.close(toastIdRef.current);
          }
          toastIdRef.current = toast({
            description: "Subtitle split successfully",
            status: "success",
            duration: 4000,
            position: "top",
          });
        },
        onError: (error) => {
          if (toastIdRef.current) {
            toast.close(toastIdRef.current);
          }
          toastIdRef.current = toast({
            title: "Error Occured.",
            description: genericErrorMsg(error),
            status: "error",
            duration: 4000,
            position: "top",
          });
        },
      }
    );
  };

  const mergeHandler = async () => {
    try {
      setMergeSubtitleWithNextByIdIsLoading(true);
      const { _id: currentSubtitleId } = subtitle;
      let nextSubtitleId;
      const queryRes = queryClient.getQueryData([
        RQ_SUBTITLES_BY_MOVIE_ID_KEY,
        page,
        SUBTITLE_MOVIE_PAGE_LIMIT,
        filter,
      ]);
      const data = queryRes.data.subtitles;
      const currentSubtitleIndex = data.indexOf(subtitle);
      if (currentSubtitleIndex >= 0 && currentSubtitleIndex < data.length - 1) {
        const nextSubtitle = data[currentSubtitleIndex + 1];
        nextSubtitleId = nextSubtitle._id;
      } else {
        const resp = await getSubtitlesByIdApi(
          movie_id,
          page + 1,
          SUBTITLE_MOVIE_PAGE_LIMIT,
          filter
        );
        const data = resp.data.subtitles;
        nextSubtitleId = data[0]._id;
      }
      await mergeSubtitleWithNextByIdMutateAsync(
        {
          currentSubtitleId: currentSubtitleId,
          nextSubtitleId: nextSubtitleId,
        },
        {
          onSuccess: () => {
            if (toastIdRef.current) {
              toast.close(toastIdRef.current);
            }
            toastIdRef.current = toast({
              description: "Subtitle merge successfully",
              status: "success",
              duration: 4000,
              position: "top",
            });
          },
          onSettled: () => {
            setMergeSubtitleWithNextByIdIsLoading(false);
          },
          onError: (error) => {
            if (toastIdRef.current) {
              toast.close(toastIdRef.current);
            }
            toastIdRef.current = toast({
              title: "Error Occured.",
              description: genericErrorMsg(error),
              status: "error",
              duration: 4000,
              position: "top",
            });
          },
        }
      );
    } catch (_error) {
      setMergeSubtitleWithNextByIdIsLoading(false);
    }
  };

  const deleteHandler = async () => {
    const { _id } = subtitle;
    deleteSubtitleMutateAsync(
      {
        subtitleId: _id,
      },
      {
        onSuccess: () => {
          if (toastIdRef.current) {
            toast.close(toastIdRef.current);
          }
          toastIdRef.current = toast({
            description: "Subtitle deleted successfully",
            status: "success",
            duration: 4000,
            position: "top",
          });
        },
        onError: (error) => {
          if (toastIdRef.current) {
            toast.close(toastIdRef.current);
          }
          toastIdRef.current = toast({
            title: "Error Occured.",
            description: genericErrorMsg(error),
            status: "error",
            duration: 4000,
            position: "top",
          });
        },
      }
    );
  };
  useEffect(() => {
    if (current_page !== 0) {
      setCardIndex((prevVal) => (prevVal += limit * current_page));
    }
  }, [limit, current_page]);

  let tagColor = "gray";
  let textColor = "white";
  if (subtitle?.qc?.key) {
    textColor = "black";
    if (subtitle.qc.key === "useful") {
      tagColor = "useful";
    } else if (subtitle.qc.key === "not_audible") {
      tagColor = "not_audible";
    } else if (subtitle.qc.key === "useless") {
      tagColor = "useless";
    }
  }

  const pauseHandler = (media) => {
    const htmlVideoEl = media.path[0];
    if (!htmlVideoEl._startTime) {
      htmlVideoEl._startTime = subtitle.start;
    }
    const playedTime = htmlVideoEl.currentTime - htmlVideoEl._startTime;
    if (playedTime >= subtitle.duration) {
      htmlVideoEl.pause();
      htmlVideoEl.src = `https://nascloudz.s3-ap-southeast-1.amazonaws.com/movie/${subtitle.movie_name}.mkv#t=${subtitle.start},${subtitle.end}`;
      htmlVideoEl.play();
    }
  };

  return (
    <Box
      position="relative"
      maxW="lg"
      borderWidth="1px"
      borderRadius="lg"
      p="5"
      overflow="hidden"
      bg={tagColor === "gray" ? "white" : tagColor}
    >
      <Tag
        variant="solid"
        bg={tagColor}
        position="absolute"
        top={{ base: "16", md: "8" }}
        right="7"
        zIndex="modal"
        boxShadow="xl"
        borderWidth="thin"
        borderColor="blackAlpha.400"
        justifyContent="center"
      >
        <Text color={textColor} fontWeight="bold">
          {cardIndex}/{subtitle_count}
        </Text>
      </Tag>
      <Box mb="4" justifyContent="center" h="250">
        {videoId === subtitle._id ? (
          <VideoPlayer
            id="singleplayer"
            url={`https://nascloudz.s3-ap-southeast-1.amazonaws.com/movie/${subtitle.movie_name}.mkv#t=${subtitle.start},${subtitle.end}`}
            controls={true}
            playing={true}
            playsinline={true}
            onPause={pauseHandler}
            ref={videoPlayerRef}
          />
        ) : (
          <Box
            pos="relative"
            cursor="pointer"
            className="group"
            overflow="hidden"
            onClick={() => setVideoId(subtitle._id)}
            bg="white"
            top="-20"
          >
            <AspectRatio ratio={1 / 1}>
              <VideoThumbnailIllustration />
            </AspectRatio>
            <PlayButton />
          </Box>
        )}
      </Box>
      {isSubtitleInfoLoading ? (
        <Center>
          <Spinner />
        </Center>
      ) : (
        <>
          <SubtitleForm
            initialValues={initialValues}
            handleSubmit={handleSubmit}
            handleSubtitleInfoUpdate={handleSubtitleInfoUpdate}
          />
          <Button
            leftIcon={<HiOutlineDotsVertical />}
            colorScheme="orange"
            onClick={mergeHandler}
            isLoading={mergeSubtitleWithNextByIdIsLoading}
            isFullWidth={true}
            mb="4"
            mt="4"
          >
            Merge with Next
          </Button>
          <Button
            leftIcon={<AiOutlineSplitCells />}
            colorScheme="blackAlpha"
            onClick={splitHandler}
            isLoading={splitSubtitleIsLoading}
            isFullWidth={true}
            mb="4"
          >
            Split
          </Button>
          <DeleteButton
            ButtonText="Delete"
            PopOverHeading="Are you sure?"
            PopOverText="Once data is deleted it cannot be restored. Press Okay to delete the data."
            clickHandler={deleteHandler}
            deleteSubtitleIsLoading={deleteSubtitleIsLoading}
            isFullWidth={true}
          />
        </>
      )}
    </Box>
  );
};
