import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Select,
} from "@chakra-ui/react";
import { Field } from "formik";

export const CustomSelect = ({
  name,
  data,
  placeholder,
  isRequired,
  label,
}) => {
  return (
    <Field name={name}>
      {({ field, form }) => (
        <FormControl
          isInvalid={form.errors[name] && form.touched[name]}
          isRequired={isRequired}
          mb={4}
        >
          <FormLabel htmlFor={name} fontSize="1rem">
            {label}
          </FormLabel>
          <Select placeholder={placeholder} {...field}>
            {data &&
              data?.length > 0 &&
              data.map((e, i) => (
                <option
                  key={i}
                  value={e.value}
                  style={{ backgroundColor: "#373838", color: "white" }}
                >
                  {e.name}
                </option>
              ))}
          </Select>
          <FormErrorMessage>{form.errors[name]}</FormErrorMessage>
        </FormControl>
      )}
    </Field>
  );
};
