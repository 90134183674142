import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  RadioGroup,
  Text,
} from "@chakra-ui/react";
import { Field } from "formik";

export const CustomRadio = ({ isRequired, label, options, name, ...rest }) => {
  return (
    <Field name={name}>
      {({ field, form }) => (
        <FormControl
          as="fieldset"
          isRequired={isRequired}
          isInvalid={form.errors[name] && form.touched[name]}
          my="4"
        >
          <FormLabel as="legend">{label}</FormLabel>
          <RadioGroup>
            <HStack>
              {options.map((option, index) => (
                <HStack key={index}>
                  <Field
                    {...field}
                    type="radio"
                    name={name}
                    value={option.value}
                    {...rest}
                  />
                  <Text>{option.key}</Text>
                </HStack>
              ))}
            </HStack>
          </RadioGroup>
          <FormErrorMessage>{form.errors[name]}</FormErrorMessage>
        </FormControl>
      )}
    </Field>
  );
};
