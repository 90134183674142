import { SimpleGrid } from "@chakra-ui/react";
import { useMemo } from "react";

export const SocialMediaGrid = (props) => {
  const columns = useMemo(() => {
    return {
      base: 2,
      md: 2,
      lg: 3,
      xl: 4,
      "2xl": 5,
    };
  }, []);

  return (
    <SimpleGrid
      columns={columns}
      columnGap={{
        base: "4",
        md: "6",
      }}
      rowGap={{
        base: "8",
        md: "10",
      }}
      {...props}
    />
  );
};
