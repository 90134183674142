import { useMutation } from "react-query";
import queryClient from "../utils/client";

const useMutationHandler = (fetcher, invalidators, removeQuery) => {
  const { mutateAsync, data, isSuccess, isLoading, isError, error } =
    useMutation((values) => fetcher(values), {
      onSuccess: (data) => {
        const respStatus = data?.status;
        if (respStatus) {
          invalidators?.forEach((invalidator) => {
            if (removeQuery) {
              queryClient.removeQueries(invalidator);
            } else {
              queryClient.invalidateQueries(invalidator);
            }
          });
        }
      },
    });

  return { mutateAsync, data, isSuccess, isLoading, isError, error };
};

export default useMutationHandler;
