import { Flex } from '@chakra-ui/react';
import { DashbaoardMobileLayout } from './DashbaoardMobileLayout';
import { Sidebar } from './Sidebar';

const DashboardLayout = ({ children }) => {
  return (
    <Flex flexDirection="column" height="100vh">
      <DashbaoardMobileLayout />
      <Flex flex="1" overflow="hidden">
        <Sidebar
          display={{
            base: 'none',
            md: 'flex',
          }}
        />
        <Flex
          display="block"
          width="full"
          direction="column"
          overflowY="auto"
          borderRightWidth="1px"
          p="6"
        >
          {children}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default DashboardLayout;
