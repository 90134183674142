import { Box } from '@chakra-ui/react';

export const Card = (props) => (
  <Box
    bg="white"
    rounded={{
      md: '2xl',
    }}
    p="8"
    {...props}
  />
);
