import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react';
import { Field } from 'formik';
import { useState } from 'react';

export const PasswordInput = (props) => {
  const { label, name, isRequired, placeholder } = props;

  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);

  return (
    <Field name={name}>
      {({ field, form }) => (
        <FormControl isRequired={isRequired} isInvalid={form.errors[name] && form.touched[name]} mb={4}>
          <FormLabel htmlFor={name}>{label}</FormLabel>
          <InputGroup size="md">
            <Input
              {...field}
              type={show ? 'text' : 'password'}
              placeholder={placeholder || 'Enter Password'}
              
              autoComplete="password"
            />
            <InputRightElement>
              <IconButton
                size="sm"
                borderRadius="base"
                onClick={handleClick}
                aria-label="Show password"
                bg="white"
                icon={show ? <ViewIcon /> : <ViewOffIcon />}
              />
            </InputRightElement>
          </InputGroup>
          <FormErrorMessage>{form.errors[name]}</FormErrorMessage>
        </FormControl>
      )}
    </Field>
  );
};
