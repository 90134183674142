import { Avatar, Box, Flex } from '@chakra-ui/react';

export const ProfileCardWithAvatar = (props) => {
  const { action, avatarProps, children, ...rest } = props;
  return (
    <Flex
      position="relative"
      direction="column"
      align={{
        sm: 'center',
      }}
      maxW="2xl"
      mx="auto"
      bg="primary"
      shadow={{
        sm: 'base',
      }}
      rounded={{
        sm: 'lg',
      }}
      px={{
        base: '6',
        md: '8',
      }}
      pb={{
        base: '6',
        md: '8',
      }}
      {...rest}
    >
      <Avatar
        mt="-10"
        borderWidth="6px"
        borderColor="white"
        size="xl"
        {...avatarProps}
      />
      <Box
        position="absolute"
        top="4"
        insetEnd={{
          base: '6',
          md: '8',
        }}
      >
        {action}
      </Box>
      {children}
    </Flex>
  );
};
