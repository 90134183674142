import React from "react";
import ReactPlayer from "react-player/lazy";

// export const VideoPlayer = ({ url, controls, ...rest }) => {
  // return (
  //   <ReactPlayer
  //     url={url}
  //     controls={controls}
  //     width="100%"
  //     height="100%"
  //     {...rest}
  //   />
  // );
// };


export const VideoPlayer = React.forwardRef(({url, controls, ...rest}, ref) => {
  return (
    <ReactPlayer
      url={url}
      controls={controls}
      width="100%"
      height="100%"
      {...rest}
      ref={ref}
    />
  );
})