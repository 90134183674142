import ConfirmAccountPage from "../pages/Auth/ConfirmAccount";
import FogetPasswordPage from "../pages/Auth/FogetPassword";
import TelegramAccountPasswordUpdatePage from "../pages/Auth/TelegramAccountPasswordUpdate";
import LoginPage from "../pages/Auth/Login";
import RegisterPage from "../pages/Auth/Register";
import ResetPasswordPage from "../pages/Auth/ResetPassword";
import DashboardPage from "../pages/Dashboard";
import AnnotationsPage from "../pages/Dashboard/Annotations";
import MoviesPage from "../pages/Dashboard/Movies";
import MyAnnotationPage from "../pages/Dashboard/MyAnnotation";
import MyVideosPage from "../pages/Dashboard/MyVideos";
import DashboardPlanPage from "../pages/Dashboard/Plan";
import DashboardProfilePage from "../pages/Dashboard/Profile";
import QualityControlPage from "../pages/Dashboard/QualityControl";
import SocialMediaPage from "../pages/Dashboard/SocialMedia";
import VocabImageMatchingPage from "../pages/Dashboard/VocabImageMatching";
import HomePage from "../pages/Home";

const routes = [
  { path: "/", key: "HOME_PAGE", component: HomePage, exact: true },
  {
    path: '/telegram/confirm/:token',
    key: "TELEGRAM_ACCOUNT_PASSWORD_UPDATE_PAGE",
    component: TelegramAccountPasswordUpdatePage,
    exact: true
  },
  {
    path: "/confirm/:token",
    key: "CONFIRM_ACCOUNT_PAGE",
    component: ConfirmAccountPage,
    exact: true,
  },
  { path: "/login", key: "LOGIN_PAGE", component: LoginPage, exact: true },
  {
    path: "/register",
    key: "REGISTER_PAGE",
    component: RegisterPage,
    exact: true,
  },
  {
    path: "/forget-password",
    key: "FORGET_PASSWORD_PAGE",
    component: FogetPasswordPage,
    exact: true,
  },
  {
    path: "/reset-password/:token",
    key: "RESET_PASSWORD_PAGE",
    component: ResetPasswordPage,
    exact: true,
  },
  {
    path: "/dashboard/home",
    key: "DASHBOARD_HOME_PAGE",
    component: DashboardPage,
    exact: true,
  },
  {
    path: "/dashboard/profile",
    key: "DASHBOARD_PROFILE_PAGE",
    component: DashboardProfilePage,
    exact: true,
  },
  {
    path: "/dashboard/plan",
    key: "DASHBOARD_PLAN_PAGE",
    component: DashboardPlanPage,
    exact: true,
  },
  {
    path: "/dashboard/quality-control-pool",
    key: "DASHBOARD_QUALITY_CONTROL_POOL_PAGE",
    component: QualityControlPage,
    exact: true,
  },
  {
    path: "/dashboard/quality-control-pool/movies/:movieId/:movieName",
    key: "DASHBOARD_MOVIE_PAGE",
    component: MoviesPage,
    exact: true,
  },
  {
    path: "/dashboard/my-videos",
    key: "DASHBOARD_MY_VIDEOS_PAGE",
    component: MyVideosPage,
    exact: true,
  },
  {
    path: "/dashboard/vocab-image-matching",
    key: "DASHBOARD_VOCAB_IMAGE_MATCHING_PAGE",
    component: VocabImageMatchingPage,
    exact: true,
  },
  {
    path: "/dashboard/annotations",
    key: "DASHBOARD_ANNOTATIONS_PAGE",
    component: AnnotationsPage,
    exact: true,
  },
  {
    path: "/dashboard/my-annotation",
    key: "DASHBOARD_MY_ANNOTATIONS_PAGE",
    component: MyAnnotationPage,
    exact: true,
  },
  {
    path: "/dashboard/social-media",
    key: "DASHBOARD_SOCIAL_MEDIA_PAGE",
    component: SocialMediaPage,
    exact: true,
  }
];

export default routes;
