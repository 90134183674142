import { Alert, AlertIcon, Box, Heading } from "@chakra-ui/react";
import { useState } from "react";
import useMutationHandler from "../../hooks/useMutationHandler";
import useQueryHandler from "../../hooks/useQueryHandler";
import {
  getSingleS3MovieApi,
  updateSingleS3MovieApi,
} from "../../services/s3movies";
import {
  RQ_MOVIE_STATUS_TABLE_KEY,
  RQ_QC_MOVIE_TABLE_KEY,
  RQ_SINGLE_MOVIE_KEY,
  RQ_SUBTITLE_ASSIGNMENT_TABLE_KEY,
} from "../../utils/constants";
import { genericErrorMsg } from "../../utils/helper";
import CustomSpinner from "../Common/CustomSpinner";
import ErrorBox from "../Common/ErrorBox";
import MovieForm from "../Forms/MovieForm";

const MovieDetail = ({ props }) => {
  const [isEdit, setIsEdit] = useState(false);
  const movie_id = props.match.params.movieId;

  const {
    data,
    isLoading,
    isError,
    error: singleS3MovieError,
  } = useQueryHandler(RQ_SINGLE_MOVIE_KEY, () => getSingleS3MovieApi(movie_id));

  const {
    mutateAsync,
    isLoading: movieFormIsLoading,
    isError: movieFormIsError,
    error: movieFormError,
  } = useMutationHandler(updateSingleS3MovieApi, [
    RQ_SINGLE_MOVIE_KEY,
    RQ_MOVIE_STATUS_TABLE_KEY,
    RQ_QC_MOVIE_TABLE_KEY,
    RQ_SUBTITLE_ASSIGNMENT_TABLE_KEY,
  ]);

  const movieSubmitHandler = (values) => {
    mutateAsync(
      { id: movie_id, body: values },
      {
        onSuccess: () => {
          setIsEdit(false);
        },
      }
    );
  };

  return (
    <>
      {isError && (
        <Box m="10">
          <Heading size="lg" mb="-3" color="primary">
            Movie Details:
          </Heading>
          <ErrorBox errorMessage={singleS3MovieError} mt="12" />
        </Box>
      )}
      {!isError && (
        <>
          {isLoading ? (
            <CustomSpinner />
          ) : (
            <>
              {movieFormIsError && (
                <Box mt="10" mx="10" mb="-5">
                  <Alert status="error">
                    <AlertIcon />
                    {genericErrorMsg(movieFormError)}
                  </Alert>
                </Box>
              )}
              <MovieForm
                data={data?.data}
                submitHandler={movieSubmitHandler}
                movieFormIsLoading={movieFormIsLoading}
                isEdit={isEdit}
                setIsEdit={setIsEdit}
              />
            </>
          )}
        </>
      )}
    </>
  );
};

export default MovieDetail;
