import { Box, Button, Divider, Flex, Spacer, Stack } from "@chakra-ui/react";
import { AiFillControl, AiOutlineLogout } from "react-icons/ai";
import { FaBookmark, FaImages, FaUserAlt } from "react-icons/fa";
import { HiAnnotation } from "react-icons/hi";
import {
  MdDashboard,
  MdPayment,
  MdPeopleOutline,
  MdVideoLibrary,
} from "react-icons/md";
import { useHistory } from "react-router-dom";
import useMutationHandler from "../../hooks/useMutationHandler";
import useQueryHandler from "../../hooks/useQueryHandler";
import { logoutApi, meApi } from "../../services/user";
import {
  ADMIN_ACCESS,
  ALL_ACCESS,
  ANNOTATE_ACCESS,
  QC_ACCESS,
  RQ_USER_KEY,
  SOCIAL_MEDIA_EDITOR_ACCESS,
} from "../../utils/constants";
import CustomSpinner from "../Common/CustomSpinner";
import { Logo } from "../Common/Logo";
import { NavLink } from "../Common/NavLink";
import { UserProfile } from "../Common/UserProfile";

export const Sidebar = (props) => {
  const {
    data,
    isLoading: meIsLoading,
    isError,
  } = useQueryHandler(RQ_USER_KEY, meApi);

  const history = useHistory();
  const { mutateAsync, isLoading } = useMutationHandler(
    logoutApi,
    [RQ_USER_KEY],
    true
  );

  const logoutUser = async () => {
    try {
      await mutateAsync(
        {},
        {
          onSuccess: () => {
            history.push("/login");
          },
          onError: (err) => {
            throw err;
          },
        }
      );
    } catch (error) {}
  };

  const userAccess = data?.data?.access || [];

  return (
    <Flex
      bg="gray.100"
      direction="column"
      borderRightWidth="1px"
      minWidth={{ base: "96", xl: "72", "2xl": "80" }}
      overflow="auto"
      {...props}
    >
      <Box as="nav" display="block" flex="1">
        <Flex direction="column" flex="1" pt="5" pb="4" overflowY="auto" px="4">
          {meIsLoading ? (
            <Box alignSelf="center" justifyContent="center" width="60">
              <CustomSpinner height="40vh" />
            </Box>
          ) : (
            <>
              <Box mb="2" alignSelf="center">
                <Logo h="6" boxSize="10rem" />
              </Box>
              <Stack spacing="6" as="nav" aria-label="Sidebar Navigation">
                <Stack spacing="1">
                  <NavLink
                    label="Dashboard"
                    icon={MdDashboard}
                    pathname="/dashboard/home"
                    accessLevel={ALL_ACCESS}
                    userAccess={userAccess}
                  />
                  <NavLink
                    label="Profile"
                    icon={FaUserAlt}
                    pathname="/dashboard/profile"
                    accessLevel={ALL_ACCESS}
                    userAccess={userAccess}
                  />
                  <NavLink
                    label="Plan"
                    icon={MdPayment}
                    pathname="/dashboard/plan"
                    accessLevel={ALL_ACCESS}
                    userAccess={userAccess}
                  />
                  <NavLink
                    label="Quality Control Pool"
                    icon={AiFillControl}
                    pathname="/dashboard/quality-control-pool"
                    accessLevel={QC_ACCESS}
                    userAccess={userAccess}
                  />
                  <NavLink
                    label="My Videos"
                    icon={MdVideoLibrary}
                    pathname="/dashboard/my-videos"
                    accessLevel={QC_ACCESS}
                    userAccess={userAccess}
                  />
                  <NavLink
                    label="Vocab Image Matching"
                    icon={FaImages}
                    pathname="/dashboard/vocab-image-matching"
                    accessLevel={ADMIN_ACCESS}
                    userAccess={userAccess}
                  />
                  <NavLink
                    label="Annotations"
                    icon={HiAnnotation}
                    pathname="/dashboard/annotations"
                    accessLevel={ANNOTATE_ACCESS}
                    userAccess={userAccess}
                  />
                  <NavLink
                    label="My Annotation"
                    icon={FaBookmark}
                    pathname="/dashboard/my-annotation"
                    accessLevel={ANNOTATE_ACCESS}
                    userAccess={userAccess}
                  />
                  <NavLink
                    label="Social Media"
                    icon={MdPeopleOutline}
                    pathname="/dashboard/social-media"
                    accessLevel={SOCIAL_MEDIA_EDITOR_ACCESS}
                    userAccess={userAccess}
                  />
                </Stack>
                <Divider />
                <Stack spacing={4}>
                  {!meIsLoading && !isError && (
                    <Box bg="gray.200" rounded="lg">
                      <UserProfile
                        name={data?.data?.name || ""}
                        email={data?.data?.email || ""}
                      />
                    </Box>
                  )}
                  <Button
                    leftIcon={<AiOutlineLogout />}
                    bg="red.500"
                    color="whiteAlpha.900"
                    variant="solid"
                    onClick={logoutUser}
                    isLoading={isLoading}
                  >
                    Logout
                  </Button>
                </Stack>
              </Stack>
            </>
          )}
          <Spacer />
        </Flex>
      </Box>
    </Flex>
  );
};
