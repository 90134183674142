import { Button } from "@chakra-ui/react";
import { FaGoogle } from "react-icons/fa";

export const GoogleLoginButton = (props) => {
  const googleLoginUrl = process.env.REACT_APP_GOOGLE_LOGIN_URL;

  const clickHandler = () => {
    window.location.href = googleLoginUrl;
  };

  return (
    <Button
      fontSize="sm"
      fontWeight="bold"
      size="lg"
      leftIcon={<FaGoogle fontSize="18px" />}
      iconSpacing="3"
      colorScheme="red"
      width="full"
      onClick={clickHandler}
      {...props}
    >
      SignIn with Google
    </Button>
  );
};
