import { Button } from "@chakra-ui/react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { PasswordInput } from "../FormControls/PasswordInput";

const TelegramUpdatePasswordSchema = Yup.object().shape({
  password: Yup.string().min(4, "Too short").required("Required"),
  confirmPassword: Yup.string().oneOf(
    [Yup.ref("password"), null],
    "Passwords must match"
  ),
});

const TelegramUpdatePasswordForm = ({ initialValues, handleSubmit }) => {
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={TelegramUpdatePasswordSchema}
    >
      {(props) => (
        <Form>
          <PasswordInput label="Password" name="password" isRequired={true} />
          <PasswordInput
            label="Confirm Password"
            name="confirmPassword"
            isRequired={true}
          />
          <Button
            mt="3"
            isFullWidth
            fontSize="md"
            fontWeight="bold"
            bgColor="primary"
            color="white"
            isLoading={props.isSubmitting}
            type="submit"
          >
            Update
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default TelegramUpdatePasswordForm;
