import { DeleteIcon } from "@chakra-ui/icons";
import {
  Button,
  ButtonGroup,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
} from "@chakra-ui/react";
import { useRef } from "react";

export const DeleteButton = ({
  ButtonText,
  PopOverHeading,
  PopOverText,
  clickHandler,
  deleteSubtitleIsLoading,
  ...rest
}) => {
  const initialFocusRef = useRef();
  return (
    <Popover
      initialFocusRef={initialFocusRef}
      placement="bottom"
      closeOnBlur={false}
    >
      <PopoverTrigger>
        <Button leftIcon={<DeleteIcon />} colorScheme="red" {...rest}>
          {ButtonText}
        </Button>
      </PopoverTrigger>

      <PopoverContent color="white" bg="primary" borderColor="primary">
        <PopoverHeader pt={4} fontWeight="bold" border="0">
          {PopOverHeading}
        </PopoverHeader>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverBody>{PopOverText}</PopoverBody>
        <PopoverFooter
          border="0"
          d="flex"
          alignItems="center"
          justifyContent="center"
          pb={4}
        >
          <ButtonGroup size="sm" isDisabled={deleteSubtitleIsLoading}>
            <Button
              ref={initialFocusRef}
              colorScheme="red"
              onClick={clickHandler}
              isLoading={deleteSubtitleIsLoading}
            >
              Okay
            </Button>
          </ButtonGroup>
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  );
};
