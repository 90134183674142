import invokeApi from "../utils/api";
import { $axiosV1 } from "../utils/axios";

export const getSubtitlesByIdApi = async (movieId, page, limit, filter) => {
  try {
    const resp = await invokeApi(
      $axiosV1,
      `/subtitles/movie/${movieId}?page=${page}&limit=${limit}&filter=${filter}`,
      "GET"
    );
    return resp;
  } catch (error) {
    throw error;
  }
};

export const getSubtitlesBySearchAndIdApi = async (
  movieId,
  page,
  limit,
  query,
  filter
) => {
  try {
    if (query !== "") {
      const resp = await invokeApi(
        $axiosV1,
        `/subtitles/movie/${movieId}/search?page=${page}&limit=${limit}&query=${query}&filter=${filter}`,
        "GET"
      );
      return resp;
    }
  } catch (error) {
    throw error;
  }
};

export const updateSubtitleById = async ({ subtitleId, body }) => {
  try {
    const resp = await invokeApi(
      $axiosV1,
      `/subtitles/${subtitleId}`,
      "PUT",
      body
    );
    return resp;
  } catch (error) {
    throw error;
  }
};

export const softDelelteSubtitleById = async ({ subtitleId }) => {
  try {
    const resp = await invokeApi(
      $axiosV1,
      `/subtitles/${subtitleId}/soft-delete`,
      "DELETE",
      {}
    );
    return resp;
  } catch (error) {
    throw error;
  }
};

export const splitSubtitleById = async ({ subtitleId }) => {
  try {
    const resp = await invokeApi(
      $axiosV1,
      `/subtitles/${subtitleId}/split`,
      "PUT",
      {}
    );
    return resp;
  } catch (error) {
    throw error;
  }
};

export const mergeSubtitleWithNextById = async ({
  currentSubtitleId,
  nextSubtitleId,
}) => {
  try {
    const resp = await invokeApi(
      $axiosV1,
      `/subtitles/${currentSubtitleId}/merge-with-next/${nextSubtitleId}`,
      "PUT",
      {}
    );
    return resp;
  } catch (error) {
    throw error;
  }
};
