import { useReducer } from "react";
import {
  PAGE_CHANGED,
  PAGE_SIZE_CHANGED,
  TOTAL_COUNT_CHANGED,
} from "../utils/constants";

const usePaginationReducer = () => {
  const initialState = {
    page: 0,
    limit: 10,
  };
  const reducer = (state, { type, payload }) => {
    switch (type) {
      case PAGE_CHANGED:
        return {
          ...state,
          page: payload,
        };
      case PAGE_SIZE_CHANGED:
        return {
          ...state,
          limit: payload,
        };
      case TOTAL_COUNT_CHANGED:
        return {
          ...state,
          totalCount: payload,
        };
      default:
        throw new Error(`Unhandled action type: ${type}`);
    }
  };

  const [{ page, limit }, dispatch] = useReducer(reducer, initialState);

  return { page, limit, dispatch };
};

export default usePaginationReducer;
