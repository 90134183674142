import { Button } from "@chakra-ui/react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { PasswordInput } from "../FormControls/PasswordInput";

const ResetPasswordSchema = Yup.object().shape({
  password: Yup.string().min(4, "Too short").required("Required"),
  confirmPassword: Yup.string().oneOf(
    [Yup.ref("password"), null],
    "Passwords must match"
  ),
});

const ResetPasswordForm = ({ initialValues, handleSubmit, isLoading }) => {
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={ResetPasswordSchema}
    >
      {(_props) => (
        <Form>
          <PasswordInput label="Password" name="password" isRequired={true} />
          <PasswordInput
            label="Confirm Password"
            name="confirmPassword"
            isRequired={true}
          />
          <Button
            mt="3"
            isFullWidth
            fontSize="md"
            fontWeight="bold"
            bgColor="primary"
            color="white"
            isLoading={isLoading}
            type="submit"
          >
            Reset
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default ResetPasswordForm;
