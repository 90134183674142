import { SearchIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  ButtonGroup,
  Center,
  Flex,
  Heading,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  SimpleGrid,
  Stack,
  Tag,
} from "@chakra-ui/react";
import ReactPaginate from "react-paginate";
import moduleStyle from "../../styles/paginate.module.css";
import { SubtitleCard } from "../Cards/SubtitleCard";
import CustomSpinner from "../Common/CustomSpinner";

const Subtitles = ({
  data,
  page,
  setPage,
  onSearchChange,
  subtitleIsLoading,
  setFilter,
  filter,
  movie_id,
}) => (
  <Box
    maxW="auto"
    mx="auto"
    px={{
      base: "4",
      md: "8",
      lg: "12",
    }}
    py={{
      base: "6",
      md: "8",
      lg: "12",
    }}
  >
    <Stack
      spacing={{
        base: "6",
        md: "8",
        lg: "12",
      }}
    >
      <Flex
        justify="space-between"
        align={{
          base: "start",
          md: "center",
        }}
        direction={{
          base: "column",
          md: "row",
        }}
      >
        <Heading
          size="lg"
          mb={{
            base: "3",
            md: "-6",
          }}
          color="primary"
        >
          All Clips:
        </Heading>
        <ButtonGroup isAttached={true}>
          <Button
            onClick={() => setFilter("all")}
            variant={filter === "all" ? "solid" : "outline"}
            bg={filter === "all" && "secondary"}
            color={filter === "all" ? "white" : "secondary"}
          >
            All
          </Button>
          <Button
            onClick={() => setFilter("unattended")}
            variant={filter === "unattended" ? "solid" : "outline"}
            bg={filter === "unattended" && "secondary"}
            color={filter === "unattended" ? "white" : "secondary"}
          >
            Unattended
          </Button>
        </ButtonGroup>
      </Flex>
      <Box
        borderWidth="thin"
        p="6"
        borderRadius="md"
        boxShadow="sm"
        bg="gray.50"
      >
        <Heading size="md" mb="6" color="primary">
          Legend:
        </Heading>
        <HStack spacing="4">
          <Tag size="lg" variant="solid" bg="useful" color="black">
            Useful
          </Tag>
          <Tag size="lg" variant="solid" bg="not_audible" color="black">
            Not Audible
          </Tag>
          <Tag size="lg" variant="solid" bg="useless" color="black">
            Useless
          </Tag>
          <Tag size="lg" variant="solid" bg="gray">
            Unattended
          </Tag>
        </HStack>
      </Box>
      <Box>
        <InputGroup>
          <InputLeftElement
            pointerEvents="none"
            children={<SearchIcon color="gray.300" />}
          />
          <Input
            placeholder="Search for subtitle"
            onChange={onSearchChange}
            type="search"
          />
        </InputGroup>
      </Box>
      {subtitleIsLoading ? (
        <CustomSpinner height="20vh" />
      ) : (
        <>
          <SimpleGrid
            columns={{
              base: 1,
              md: 1,
              lg: 2,
              xl: 2,
              "2xl": 4,
            }}
            gap={{
              base: "4",
              md: "6",
              lg: "8",
            }}
          >
            {data?.data?.subtitles.map((subtitle, index) => (
              <SubtitleCard
                subtitle={subtitle}
                key={subtitle?._id}
                current_page={data?.data?.current_page}
                limit={data?.data?.limit}
                subtitle_count={data?.data?.subtitle_count}
                index={index}
                filter={filter}
                page={page}
                movie_id={movie_id}
              />
            ))}
          </SimpleGrid>
          <Center>
            <ReactPaginate
              pageCount={data?.data?.total_pages}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={(page) => {
                setPage(page.selected);
              }}
              initialPage={page}
              containerClassName={moduleStyle.paginationContainer}
              previousClassName={moduleStyle.previous}
              nextClassName={moduleStyle.next}
              pageClassName={moduleStyle.paginationLiTag}
              pageLinkClassName={moduleStyle.paginationLink}
              activeClassName={moduleStyle.active}
              activeLinkClassName={moduleStyle.activePaginationLink}
              breakClassName={moduleStyle.break}
              breakLinkClassName={moduleStyle.breakLink}
            />
          </Center>
        </>
      )}
    </Stack>
  </Box>
);

export default Subtitles;
