import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Heading,
  Stack,
  StackDivider,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useMemo, useRef, useState } from "react";
import { NavBreadcrumb } from "../../../components/Common/Breadcrumb";
import CustomSpinner from "../../../components/Common/CustomSpinner";
import ErrorBox from "../../../components/Common/ErrorBox";
import { ProfileCard } from "../../../components/Cards/ProfileCard";
import { ProfileCardWithAvatar } from "../../../components/Cards/ProfileCardWithAvatar";
import { UserInfoCard } from "../../../components/Cards/UserInfoCard";
import ProfileUpdateForm from "../../../components/Forms/ProfileUpdateForm";
import DashboardLayout from "../../../components/Layouts/DashboardLayout";
import useMutationHandler from "../../../hooks/useMutationHandler";
import useQueryHandler from "../../../hooks/useQueryHandler";
import { meApi, updateUserProfileApi } from "../../../services/user";
import { RQ_USER_KEY } from "../../../utils/constants";
import { genericErrorMsg, getDateInMonthDayYear } from "../../../utils/helper";

const Index = () => {
  const toast = useToast();
  const toastIdRef = useRef();
  const [errorMessage, setErrorMessage] = useState("");

  const { data, isLoading, isError } = useQueryHandler(RQ_USER_KEY, meApi);
  const { mutateAsync, isLoading: mutationIsLoading } = useMutationHandler(
    updateUserProfileApi,
    [RQ_USER_KEY]
  );

  const currentlinklocationdata = useMemo(
    () => [
      {
        label: "Dashboard",
        isCurrentPage: false,
        canNavigate: false,
        to: "/dashboard/home",
      },
      {
        label: "Profile",
        isCurrentPage: true,
        canNavigate: true,
        to: "/dashboard/profile",
      },
    ],
    []
  );

  const initialValues = {
    name: data?.data?.name || "",
    nick_name: data?.data?.nick_name || "",
    email: data?.data?.email || "",
    pratice_language: data?.data?.pratice_language || "",
    support_language: data?.data?.support_language || "",
  };

  const handleSubmit = (values) => {
    let data = { ...values };
    delete data.email;
    setErrorMessage("");
    mutateAsync(data, {
      onSuccess: () => {
        if (toastIdRef.current) {
          toast.close(toastIdRef.current);
        }
        toastIdRef.current = toast({
          title: "Account updated.",
          description: "We've updated your account with new details.",
          status: "success",
          duration: 9000,
          isClosable: true,
          position: "top-right",
        });
      },
      onError: (err) => {
        const message = genericErrorMsg(err);
        setErrorMessage(message);
      },
    });
  };

  return (
    <DashboardLayout>
      <NavBreadcrumb currentlinklocationdata={currentlinklocationdata} />
      {isLoading ? (
        <CustomSpinner />
      ) : (
        <>
          {isError ? (
            <Box mt="10">
              <ErrorBox />
            </Box>
          ) : (
            <>
              <Box as="section" mt="12" />
              <ProfileCardWithAvatar
                maxW="10xl"
                avatarProps={{
                  name: data?.data?.name || data?.data?.email,
                }}
              >
                <ProfileCard>
                  <Heading
                    size="lg"
                    fontWeight="extrabold"
                    letterSpacing="tight"
                    color="white"
                  >
                    {data?.data?.name || data?.data?.email}
                  </Heading>
                  <Text color="white" mt="2">
                    {getDateInMonthDayYear(data?.data?.joinedAt)}
                  </Text>
                  <UserInfoCard
                    power={15.5}
                    challenges={2}
                    hints={0}
                    difficult={0}
                  />
                </ProfileCard>
              </ProfileCardWithAvatar>

              <Box
                px={{ base: "4", md: "10" }}
                py="8"
                maxWidth="5xl"
                mx="auto"
                mb={{ base: "8", lg: "0" }}
              >
                <Stack spacing="4" divider={<StackDivider />}>
                  <Heading size="lg" as="h1" paddingBottom="4" color="primary">
                    Account Settings
                  </Heading>
                  {errorMessage && (
                    <Alert status="error">
                      <AlertIcon />
                      <AlertDescription>{errorMessage}</AlertDescription>
                    </Alert>
                  )}
                  <ProfileUpdateForm
                    handleSubmit={handleSubmit}
                    isLoading={mutationIsLoading}
                    initialValues={initialValues}
                  />
                </Stack>
              </Box>
            </>
          )}
        </>
      )}
    </DashboardLayout>
  );
};

export default Index;
