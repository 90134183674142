import { useQuery } from "react-query";

const useQueryHandler = (key, fetcher) => {
  const { data, isLoading, isError, error, isSuccess } = useQuery(
    key,
    fetcher,
    {
      retry: false,
    }
  );

  return { data, isLoading, isError, error, isSuccess };
};

export default useQueryHandler;
