import invokeApi from "../utils/api";
import { $axiosV1 } from "../utils/axios";

export const getS3MoviesApi = async (page, limit) => {
  try {
    const resp = await invokeApi(
      $axiosV1,
      `/s3/movies?page=${page}&limit=${limit}`,
      "GET"
    );
    return resp;
  } catch (error) {
    throw error;
  }
};

export const getSelectedS3MoviesApi = async (page, limit) => {
  try {
    const resp = await invokeApi(
      $axiosV1,
      `/s3/movies/selected?page=${page}&limit=${limit}`,
      "GET"
    );
    return resp;
  } catch (error) {
    throw error;
  }
};

export const getSingleS3MovieApi = async (id) => {
  try {
    const resp = await invokeApi($axiosV1, `/s3/movies/${id}`, "GET");
    return resp;
  } catch (error) {
    throw error;
  }
};

export const updateSingleS3MovieApi = async (data) => {
  try {
    const resp = await invokeApi(
      $axiosV1,
      `/s3/movies/${data.id}`,
      "PUT",
      data.body
    );
    return resp;
  } catch (error) {
    throw error;
  }
};

export const searchS3MoviesApi = async (query, page, limit) => {
  try {
    if (query !== "") {
      const resp = await invokeApi(
        $axiosV1,
        `/s3/movies/search?query=${query}&page=${page}&limit=${limit}`,
        "GET"
      );
      return resp;
    }
  } catch (error) {
    throw error;
  }
};

export const searchSelectedS3MoviesApi = async (query, page, limit) => {
  try {
    if (query !== "") {
      const resp = await invokeApi(
        $axiosV1,
        `/s3/movies/selected/search?query=${query}&page=${page}&limit=${limit}`,
        "GET"
      );
      return resp;
    }
  } catch (error) {
    throw error;
  }
};

export const updates3MovieStatus = async (data) => {
  try {
    const resp = await invokeApi(
      $axiosV1,
      `/s3/movies/${data.id}/status`,
      "PUT",
      data.body
    );
    return resp;
  } catch (error) {
    throw error;
  }
};

export const updates3MovieQcActor = async (data) => {
  try {
    const resp = await invokeApi(
      $axiosV1,
      `/s3/movies/qc-select/${data.id}`,
      "PUT",
      {}
    );
    return resp;
  } catch (error) {
    throw error;
  }
};

export const deselectS3MovieQcActor = async (data) => {
  try {
    const resp = await invokeApi(
      $axiosV1,
      `/s3/movies/qc-deselect/${data.id}`,
      "PUT",
      {}
    );
    return resp;
  } catch (error) {
    throw error;
  }
};

export const getS3MoviesQcApi = async (page, limit) => {
  try {
    const resp = await invokeApi(
      $axiosV1,
      `/s3/movies/qc?page=${page}&limit=${limit}`,
      "GET"
    );
    return resp;
  } catch (error) {
    throw error;
  }
};

export const searchS3MoviesQcApi = async (query, page, limit) => {
  try {
    if (query !== "") {
      const resp = await invokeApi(
        $axiosV1,
        `/s3/movies/search/qc?query=${query}&page=${page}&limit=${limit}`,
        "POST"
      );
      return resp;
    }
  } catch (error) {
    throw error;
  }
};
