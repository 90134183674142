import { Box, Button, Center } from "@chakra-ui/react";
import { Form, Formik } from "formik";
import { HiPencilAlt, HiSave } from "react-icons/hi";
import { MdCancel } from "react-icons/md";
import * as Yup from "yup";
import useLanguages from "../../hooks/useLanguages";
import { Card } from "../Cards/EditableCard/Card";
import { CardContent } from "../Cards/EditableCard/CardContent";
import { CardHeader } from "../Cards/EditableCard/CardHeader";
import { Property } from "../Cards/EditableCard/Property";
import EditInput from "../FormControls/EditInput";
import EditSelect from "../FormControls/EditSelect";

const MovieForm = ({
  data,
  submitHandler,
  movieFormIsLoading,
  isEdit,
  setIsEdit,
}) => {
  const { languagesAvailable } = useLanguages();

  const initialValues = {
    movie_file_name: data.movie_file_name || "",
    imdb_link: data.imdb_link || "",
    language: data.language || "",
  };

  const movieSchema = Yup.object().shape({
    movie_file_name: Yup.string().required("Required"),
    imdb_link: Yup.string().required("Required"),
    language: Yup.string().required("Required"),
  });

  return (
    <Box
      as="section"
      py="12"
      px={{
        md: "8",
      }}
    >
      <Card maxW="auto">
        <CardHeader
          title="Movie Info"
          action={
            <>
              {!isEdit && (
                <Button
                  minW="20"
                  leftIcon={<HiPencilAlt />}
                  onClick={() => setIsEdit((prevData) => !prevData)}
                  bg="secondary"
                  color="white"
                  variant="solid"
                  size="sm"
                >
                  Edit
                </Button>
              )}
            </>
          }
        />
        <CardContent>
          {isEdit ? (
            <Formik
              initialValues={initialValues}
              onSubmit={submitHandler}
              validationSchema={movieSchema}
            >
              {(_props) => (
                <Form>
                  <EditInput
                    label="Movie Name"
                    placeholder="Enter Movie Name"
                    name="movie_file_name"
                    isRequired={true}
                  />
                  <EditInput
                    label="IMDB Link"
                    placeholder="Enter IMDB link"
                    name="imdb_link"
                    isRequired={true}
                  />
                  <EditSelect
                    label="Language"
                    name="language"
                    data={languagesAvailable}
                    placeholder="Select a language"
                    isRequired={true}
                  />
                  <Center mt="2" mb="5">
                    <Button
                      leftIcon={<MdCancel />}
                      onClick={() => setIsEdit((prevData) => !prevData)}
                      colorScheme="red"
                      variant="solid"
                      size="sm"
                      mr="5"
                      disabled={movieFormIsLoading}
                    >
                      Cancel
                    </Button>
                    <Button
                      leftIcon={<HiSave />}
                      bg="secondary"
                      color="white"
                      variant="solid"
                      size="sm"
                      type="submit"
                      isLoading={movieFormIsLoading}
                    >
                      Save
                    </Button>
                  </Center>
                </Form>
              )}
            </Formik>
          ) : (
            <>
              <Property
                label="Movie Name"
                value={initialValues.movie_file_name}
              />
              <Property
                label="IMDB Link"
                value={initialValues.imdb_link || ""}
              />
              <Property label="Language" value={initialValues.language || ""} />
            </>
          )}
        </CardContent>
      </Card>
    </Box>
  );
};

export default MovieForm;
