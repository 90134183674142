const invokeApi = async (axiosInstance, path, method = "GET", body = {}) => {
  try {
    const instance = await axiosInstance({
      method: method,
      url: path,
      data: JSON.stringify(body),
    });
    return instance.data;
  } catch (error) {
    throw error;
  }
};
export default invokeApi;
