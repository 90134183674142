import {
  Alert,
  AlertIcon,
  Box,
  Center,
  LightMode,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Card } from "../../components/Cards/BasicCard";
import { DividerWithText } from "../../components/Common/DividerWithText";
import { GoogleLoginButton } from "../../components/Buttons/GoogleLoginButton";
import { Logo } from "../../components/Common/Logo";
import LoginForm from "../../components/Forms/LoginForm";
import useMutationHandler from "../../hooks/useMutationHandler";
import useQueryHandler from "../../hooks/useQueryHandler";
import { loginApi, meApi } from "../../services/user";
import { RQ_USER_KEY } from "../../utils/constants";
import { genericErrorMsg } from "../../utils/helper";

const Login = () => {
  let history = useHistory();
  let location = useLocation();
  const [errorMessage, setErrorMessage] = useState("");

  const { mutateAsync, isLoading } = useMutationHandler(loginApi, [
    RQ_USER_KEY,
  ]);
  
  const {
    data,
    error,
    isLoading: isLoadingQuery,
  } = useQueryHandler(RQ_USER_KEY, meApi);

  const serverErrorMessage = new URLSearchParams(location.search).get(
    "error_message"
  );

  useEffect(() => {
    setErrorMessage(serverErrorMessage);
  }, [serverErrorMessage]);

  useEffect(() => {
    if (!isLoadingQuery) {
      if (!error && data) {
        history.replace("/dashboard/home");
      }
    }
  }, [data, error, isLoadingQuery, history]);

  const handleSubmit = async (values) => {
    try {
      setErrorMessage("");
      await mutateAsync(values, {
        onSuccess: () => {
          let { from } = location.state || {
            from: { pathname: "/dashboard/home" },
          };
          history.replace(from);
        },
      });
    } catch (error) {
      const errorMsg = genericErrorMsg(error);
      setErrorMessage(errorMsg);
    }
  };

  const initialValues = {
    email: "",
    password: "",
  };

  return (
    <Box
      as="section"
      bgGradient={{ md: "linear(to-r, blue.600, purple.600)" }}
      minH="100vh"
      py="20"
    >
      <Card maxW="2xl" mx="auto" textAlign="center">
        <Stack maxW="lg" mx="auto" spacing="8">
          <Center>
            <Logo boxSize={{ base: "8rem", md: "10rem" }} />
          </Center>
          {errorMessage && (
            <Alert status="error">
              <AlertIcon />
              {errorMessage}
            </Alert>
          )}
          <LoginForm
            isLoading={isLoading}
            handleSubmit={handleSubmit}
            initialValues={initialValues}
          />
          <DividerWithText mt="6">or continue with</DividerWithText>
          <LightMode>
            <GoogleLoginButton />
          </LightMode>
          <Box fontSize="sm">
            <Text fontWeight="medium" color="gray.600">
              Don't have an account?
            </Text>
            <Text fontWeight="semibold" color="primary">
              <Link to="/register">Register today</Link>
            </Text>
          </Box>
        </Stack>
      </Card>
    </Box>
  );
};

export default Login;
