import { Avatar, Flex, HStack, Text } from '@chakra-ui/react';

export const UserProfile = (props) => {
  const { name, image, email } = props;
  return (
    <HStack spacing="4" px="2" flexShrink={0} p="4">
      <Avatar size="sm" name={name || email} src={image} />
      <Flex direction="column" fontWeight="medium">
        <Text fontSize="sm" color="gray.800">
          {name}
        </Text>
        <Text fontSize="xs" lineHeight="shorter" color="gray.800">
          {email}
        </Text>
      </Flex>
    </HStack>
  );
};
