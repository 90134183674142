import { Button, Flex } from "@chakra-ui/react";
import { Form, Formik } from "formik";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { UnderlineLink } from "../Common/UnderlineLink";
import { CustomInput } from "../FormControls/CustomInput";
import { PasswordInput } from "../FormControls/PasswordInput";

const LoginSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Required"),
  password: Yup.string().min(4, "Too short").required("Required"),
});

const LoginForm = ({ initialValues, handleSubmit, isLoading }) => {
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={LoginSchema}
    >
      {(_props) => (
        <Form>
          <CustomInput
            placeholder="Enter email address"
            label="Email Address"
            name="email"
            isRequired={true}
          />
          <PasswordInput label="Password" name="password" isRequired={true} />
          <Flex align="center" justify="space-between" my="4">
            <Link to="/forget-password">
              <UnderlineLink fontSize="sm">Forgot Password ?</UnderlineLink>
            </Link>
          </Flex>
          <Button
            mt="3"
            isFullWidth
            fontSize="md"
            fontWeight="bold"
            bgColor="primary"
            color="white"
            isLoading={isLoading}
            type="submit"
          >
            Sign In
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default LoginForm;
