import { Box, Flex, Heading, useToast } from "@chakra-ui/react";
import { useState, useRef } from "react";
import { useAsyncDebounce } from "react-table";
import { NavBreadcrumb } from "../../../components/Common/Breadcrumb";
import ErrorBox from "../../../components/Common/ErrorBox";
import DashboardLayout from "../../../components/Layouts/DashboardLayout";
import { CustomTable } from "../../../components/Table/CustomTable";
import { columns as SelectedMovieColumns } from "../../../datatables/selectedMovie";
import useMutationHandler from "../../../hooks/useMutationHandler";
import usePaginationReducer from "../../../hooks/usePaginationReducer";
import useQueryHandler from "../../../hooks/useQueryHandler";
import {
  deselectS3MovieQcActor,
  getSelectedS3MoviesApi,
  searchSelectedS3MoviesApi,
} from "../../../services/s3movies";
import queryClient from "../../../utils/client";
import {
  RQ_MOVIE_STATUS_TABLE_KEY,
  RQ_MOVIE_STATUS_TABLE_SEARCH_KEY,
  RQ_QC_MOVIE_SELECTED_SEARCH_TABLE_KEY,
  RQ_QC_MOVIE_SELECTED_TABLE_KEY,
  RQ_QC_MOVIE_TABLE_KEY,
  RQ_QC_MOVIE_TABLE_SEARCH_KEY,
} from "../../../utils/constants";
import { genericErrorMsg } from "../../../utils/helper";

const currentlinklocationdata = [
  {
    label: "Dashboard",
    isCurrentPage: false,
    canNavigate: false,
    to: "/dashboard/home",
  },
  {
    label: "My Videos",
    isCurrentPage: true,
    canNavigate: true,
    to: "/dashboard/my-videos",
  },
];

const Index = () => {
  const toast = useToast();
  const toastIdRef = useRef();

  const [searchValue, setSearchValue] = useState("");
  const [showSearchData, setShowSearchData] = useState(false);

  const {
    page: s3SelectedMoviePage,
    limit: s3SelectedMovieLimit,
    dispatch: s3SelectedMovieNotSearchDispatch,
  } = usePaginationReducer();

  const {
    page: s3SelectedSearchMoviePage,
    limit: s3SelectedSearchLimit,
    dispatch: s3SelectedSearchMovieDispatch,
  } = usePaginationReducer();

  const {
    data: s3Movie,
    isLoading: s3MovieWithoutSearchIsLoading,
    isError: isS3MovieError,
    error: s3MovieErrorMessage,
  } = useQueryHandler(
    [RQ_QC_MOVIE_SELECTED_TABLE_KEY, s3SelectedMoviePage, s3SelectedMovieLimit],
    () => getSelectedS3MoviesApi(s3SelectedMoviePage, s3SelectedMovieLimit),
    {
      keepPreviousData: true,
      staleTime: Infinity,
    }
  );

  const {
    data: s3SearchMovie,
    isLoading: s3MovieSearchIsLoading,
    isError: isS3SearchMovieError,
    error: s3SearchMovieErrorMessage,
  } = useQueryHandler(
    [
      RQ_QC_MOVIE_SELECTED_SEARCH_TABLE_KEY,
      searchValue,
      s3SelectedSearchMoviePage,
      s3SelectedSearchLimit,
    ],
    () =>
      searchSelectedS3MoviesApi(
        searchValue,
        s3SelectedSearchMoviePage,
        s3SelectedSearchLimit
      )
  );

  const { mutateAsync: mutateAsyncMovieStatus } = useMutationHandler(
    deselectS3MovieQcActor,
    [
      RQ_QC_MOVIE_TABLE_KEY,
      RQ_QC_MOVIE_SELECTED_TABLE_KEY,
      RQ_QC_MOVIE_TABLE_SEARCH_KEY,
      RQ_MOVIE_STATUS_TABLE_KEY,
      RQ_MOVIE_STATUS_TABLE_SEARCH_KEY,
    ]
  );

  const onS3MovieSearch = useAsyncDebounce(async (value) => {
    if (value) {
      setShowSearchData(true);
      setSearchValue(value);
      queryClient.invalidateQueries([
        RQ_QC_MOVIE_SELECTED_SEARCH_TABLE_KEY,
        searchValue,
        s3SelectedSearchMoviePage,
        s3SelectedSearchLimit,
      ]);
    } else if (!value) {
      setShowSearchData(false);
    }
  }, 150);

  const mutateMovieQcStatus = async ({ id }) => {
    await mutateAsyncMovieStatus(
      { id },
      {
        onError: (error) => {
          const errorMessage = genericErrorMsg(error);
          if (toastIdRef.current) {
            toast.close(toastIdRef.current);
          }
          toastIdRef.current = toast({
            title: "Error Occured.",
            description: errorMessage,
            status: "error",
            duration: 3000,
            isClosable: true,
            position: "top",
          });
        },
      }
    );
  };

  const s3MovieData = showSearchData ? s3SearchMovie : s3Movie;
  const s3MovieIsLoading = showSearchData
    ? s3MovieSearchIsLoading
    : s3MovieWithoutSearchIsLoading;
  const s3MovieError = showSearchData ? isS3SearchMovieError : isS3MovieError;
  const s3MovieDispatch = showSearchData
    ? s3SelectedSearchMovieDispatch
    : s3SelectedMovieNotSearchDispatch;
  const s3CurrentPage = showSearchData
    ? s3SelectedSearchMoviePage
    : s3SelectedMoviePage;
  const s3CurrentPageLimit = showSearchData
    ? s3SelectedSearchLimit
    : s3SelectedMovieLimit;

  const s3ErrorMessage = showSearchData
    ? s3SearchMovieErrorMessage
    : s3MovieErrorMessage;

  return (
    <DashboardLayout>
      <NavBreadcrumb currentlinklocationdata={currentlinklocationdata} />
      <Box mt="10" justifySelf="center" alignItems="center">
        <Box overflowX="auto" mt="5" borderWidth="1px" borderRadius="lg" p="5">
          <Flex
            direction={{
              base: "column",
              lg: "row",
            }}
            mb={{
              base: "5",
              lg: "0",
            }}
            justifyContent="space-between"
          >
            <Heading size="lg" mb="6" color="primary">
              My Videos
            </Heading>
          </Flex>
          {!s3MovieError && (
            <CustomTable
              isLoading={s3MovieIsLoading}
              tableData={s3MovieData?.data?.movies || []}
              Columns={SelectedMovieColumns}
              currentPage={s3CurrentPage}
              limit={s3CurrentPageLimit}
              dispatch={s3MovieDispatch}
              totalCount={s3MovieData?.data?.total_pages || 0}
              onSearch={onS3MovieSearch}
              mutateFnObjects={{ mutateMovieQcStatus }}
            />
          )}
          {s3MovieError && <ErrorBox errorMessage={s3ErrorMessage} />}
        </Box>
      </Box>
    </DashboardLayout>
  );
};

export default Index;
