import invokeApi from "../utils/api";
import { $axiosV1 } from "../utils/axios";

export const getUsefulSocialMediaSubtitles = async ({
  search_query,
  no_of_videos,
  movie_name,
}) => {
  try {
    const resp = await invokeApi(
      $axiosV1,
      `/social-media/subtitles/useful?search_query=${search_query}&no_of_videos=${no_of_videos}&movie_name=${movie_name}`,
      "POST",
      {}
    );
    return resp;
  } catch (error) {
    throw error;
  }
};
