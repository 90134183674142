export const RQ_USER_KEY = "user",
  RQ_SUBTITLE_ASSIGNMENT_TABLE_KEY = "subtitle_assignment_table",
  RQ_SUBTITLE_ASSIGNMENT_TABLE_SEARCH_KEY = "subtitle_assignment_search_table",
  RQ_MOVIE_STATUS_TABLE_KEY = "movie_status_table",
  RQ_MOVIE_STATUS_TABLE_SEARCH_KEY = "movie_status_search_table";

export const RQ_QC_MOVIE_TABLE_KEY = "qc_movie_table",
  RQ_QC_MOVIE_TABLE_SEARCH_KEY = "qc_movie_search_table";

export const RQ_SINGLE_MOVIE_KEY = "single_movie";

export const RQ_SUBTITLES_BY_MOVIE_ID_KEY = "subtitle_by_movie_id_list",
  RQ_SEARCH_SUBTITLES_BY_MOVIE_ID_AND_QUERY_KEY =
    "subtitle_by_movie_id_and_query";

export const RQ_QC_MOVIE_SELECTED_TABLE_KEY = "qc_movie_selected_table",
  RQ_QC_MOVIE_SELECTED_SEARCH_TABLE_KEY = "qc_search_movie_selected_table";

export const PAGE_CHANGED = "PAGE_CHANGED",
  PAGE_SIZE_CHANGED = "PAGE_SIZE_CHANGED",
  TOTAL_COUNT_CHANGED = "TOTAL_COUNT_CHANGED";

export const ALL_ACCESS = [
    "admin",
    "superadmin",
    "basic",
    "qc",
    "annotate",
    "social_media_editor",
  ],
  ADMIN_ACCESS = ["admin", "superadmin"],
  QC_ACCESS = ["admin", "superadmin", "qc"],
  ANNOTATE_ACCESS = ["admin", "superadmin", "annotate"],
  SOCIAL_MEDIA_EDITOR_ACCESS = ["admin", "superadmin", "social_media_editor"];

export const SUBTITLE_MOVIE_PAGE_LIMIT = 16;
