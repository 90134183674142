import { chakra } from "@chakra-ui/react";

export const VideoThumbnailIllustration = () => {
  return (
    <chakra.svg
      id="currentIllo"
      data-name="Layer 1"
      width="1016.04768"
      height="587.85556"
      viewBox="0 0 1016.04768 587.85556"
      className="injected-svg DownloadModal__ImageFile-sc-p17csy-5 iIfSkb grid_media"
    >
      <path
        d="M611.422,708.09287l-1.26715-.475c-.27844-.10492-27.99249-10.72309-40.975-34.79938-12.983-24.07745-6.62585-53.06671-6.56012-53.35614l.2992-1.32019,1.2666.475c.27844.10492,27.99194,10.72308,40.975,34.79937,12.983,24.0774,6.62585,53.06672,6.56012,53.35614ZM571.2587,671.69773c10.9762,20.35663,32.87671,30.79145,38.42566,33.17365,1.055-5.94727,4.36365-29.997-6.60278-50.33405-10.96527-20.33471-32.87439-30.7857-38.42566-33.17364-1.0556,5.95068-4.363,29.99817,6.60278,50.334Z"
        transform="translate(-91.97616 -156.07222)"
        fill="#3f3d56"
      />
      <path
        d="M579.10141,664.37284c23.33124,14.03681,32.31678,41.91785,32.31678,41.91785s-28.8418,5.1228-52.173-8.914-32.31677-41.91785-32.31677-41.91785S555.77024,650.336,579.10141,664.37284Z"
        transform="translate(-91.97616 -156.07222)"
        fill="#d0cde1"
      />
      <path
        d="M1023.23172,706.77262c-.06573-.28943-6.42291-29.27868,6.56018-53.35614,12.983-24.07629,40.69654-34.69446,40.975-34.79937l1.2666-.475.2992,1.32019c.06567.28943,6.42285,29.27869-6.56018,53.35614-12.98243,24.07629-40.69654,34.69446-40.975,34.79938l-1.26715.475Zm47.0647-85.409c-5.55127,2.38794-27.46045,12.83892-38.42566,33.17364-10.96643,20.337-7.65784,44.38678-6.60278,50.334,5.54895-2.38214,27.44946-12.817,38.42566-33.17365C1074.65946,651.36186,1071.35209,627.31437,1070.29642,621.36363Z"
        transform="translate(-91.97616 -156.07222)"
        fill="#3f3d56"
      />
      <path
        d="M1055.85086,664.37284c-23.33117,14.03681-32.31677,41.91785-32.31677,41.91785s28.8418,5.1228,52.173-8.914,32.31677-41.91785,32.31677-41.91785S1079.18216,650.336,1055.85086,664.37284Z"
        transform="translate(-91.97616 -156.07222)"
        fill="#3c669d"
      />
      <rect
        x="424.49316"
        y="549.51416"
        width="556.10754"
        height="2.36139"
        fill="#3f3d56"
      />
      <rect width="598" height="321" fill="#3f3d56" />
      <rect x="30" y="286" width="545" height="13" fill="#d0cde1" />
      <rect x="30" y="286" width="314" height="13" fill="#3c669d" />
      <polygon
        points="30 276.445 29.974 258.437 50.36 267.412 30 276.445"
        fill="#3c669d"
      />
      <rect x="62" y="258" width="7" height="18" fill="#3c669d" />
      <rect x="76" y="258" width="7" height="18" fill="#3c669d" />
      <path
        d="M656.97617,406.07221h-532v-221h532Zm-530-2h528v-217h-528Z"
        transform="translate(-91.97616 -156.07222)"
        fill="#d0cde1"
      />
      <circle cx="300" cy="141" r="47" fill="#3c669d" />
      <rect x="357.02386" y="106.16776" width="60" height="2" fill="#d0cde1" />
      <rect x="178.02386" y="185.16776" width="60" height="2" fill="#d0cde1" />
      <path
        d="M531,272.24a9,9,0,1,1,9-9A9,9,0,0,1,531,272.24Zm0-16a7,7,0,1,0,7,7A7,7,0,0,0,531,256.24Z"
        transform="translate(-91.97616 -156.07222)"
        fill="#d0cde1"
      />
      <path
        d="M531,361.24a9,9,0,1,1,9-9A9,9,0,0,1,531,361.24Zm0-16a7,7,0,1,0,7,7A7,7,0,0,0,531,345.24Z"
        transform="translate(-91.97616 -156.07222)"
        fill="#d0cde1"
      />
      <path
        d="M563,361.24a9,9,0,1,1,9-9A9,9,0,0,1,563,361.24Zm0-16a7,7,0,1,0,7,7A7,7,0,0,0,563,345.24Z"
        transform="translate(-91.97616 -156.07222)"
        fill="#d0cde1"
      />
      <path
        d="M499,361.24a9,9,0,1,1,9-9A9,9,0,0,1,499,361.24Zm0-16a7,7,0,1,0,7,7A7,7,0,0,0,499,345.24Z"
        transform="translate(-91.97616 -156.07222)"
        fill="#d0cde1"
      />
      <path
        d="M245,351.24a9,9,0,1,1,9-9A9,9,0,0,1,245,351.24Zm0-16a7,7,0,1,0,7,7h0A7.00818,7.00818,0,0,0,245,335.24Z"
        transform="translate(-91.97616 -156.07222)"
        fill="#d0cde1"
      />
      <path
        d="M213,351.24a9,9,0,1,1,9-9A9,9,0,0,1,213,351.24Zm0-16a7,7,0,1,0,7,7h0A7.00818,7.00818,0,0,0,213,335.24Z"
        transform="translate(-91.97616 -156.07222)"
        fill="#d0cde1"
      />
      <rect x="438.02386" y="124.16776" width="2" height="50" fill="#d0cde1" />
      <path
        d="M854.01269,291.331s-16.544-2.36344-24.816,28.36114-10.63544,33.088-10.63544,33.088,48.45032,7.09031,49.632,4.72687S878.82867,298.42128,854.01269,291.331Z"
        transform="translate(-91.97616 -156.07222)"
        fill="#2f2e41"
      />
      <circle cx="752.58279" cy="91.53531" r="31.9063" fill="#ffb8b8" />
      <path
        d="M830.37835,273.60527s10.63544,27.17944,7.09027,30.72458,43.72345,8.272,43.72345,8.272,3.54517-17.72571-10.63543-28.36114c0,0-5.90857-17.72571-4.72687-21.27088S830.37835,273.60527,830.37835,273.60527Z"
        transform="translate(-91.97616 -156.07222)"
        fill="#ffb8b8"
      />
      <path
        d="M858.73956,285.42241a85.21537,85.21537,0,0,0-31.90631,21.27087c-14.18061,15.36231-21.27088,20.08914-21.27088,20.08914s-16.544,5.90857-10.63543,34.26975c2.95428,14.18057.88629,30.13373-1.92029,42.54175a265.81988,265.81988,0,0,0-6.35175,58.65557v12.24732s-108.71771,228.071-72.08459,243.43329,69.72119,20.08917,72.08459,16.544,44.90515-187.8927,44.90515-187.8927,11.81714,197.34638,41.36,197.34638,77.99316-8.272,77.99316-8.272l-60.26745-236.343s14.1806-40.17829-21.27088-68.53946L864.648,417.77446c19.60449.01,28.56616-124.06967,8.272-134.71552C872.9201,283.059,868.19323,279.51384,858.73956,285.42241Z"
        transform="translate(-91.97616 -156.07222)"
        fill="#2f2e41"
      />
      <path
        d="M829.19665,349.235l-16.544,43.72345-38.99658,81.53836S755.93035,527.674,770.1109,527.674s24.816-50.81372,24.816-50.81372l43.72345-68.53946,24.816-61.44918Z"
        transform="translate(-91.97616 -156.07222)"
        fill="#ffb8b8"
      />
      <ellipse
        cx="658.63644"
        cy="576.03842"
        rx="20.67999"
        ry="11.81714"
        fill="#2f2e41"
      />
      <ellipse
        cx="816.98624"
        cy="576.03842"
        rx="20.67999"
        ry="11.81714"
        fill="#2f2e41"
      />
      <path
        d="M856.87616,202.86485H856.876a25.34175,25.34175,0,0,0-4.89258.48651,21.44172,21.44172,0,0,0-9.34021-2.15271h-.84777c-16.34473,0-29.59461,14.7901-29.59461,33.03461v.00006h5.47669l.88452-6.73224,1.29675,6.73224h4.56226a64.57573,64.57573,0,0,0-2.119,16.55035v33.62391h8.28027l4.794-12.45795-1.19855,12.45795h53.22315l4.35815-11.32538-1.0896,11.32538h5.99243V258.00676C896.66192,227.55278,878.84918,202.86485,856.87616,202.86485Z"
        transform="translate(-91.97616 -156.07222)"
        fill="#2f2e41"
      />
      <path
        d="M753.16557,39.42013h7.811a6.09448,6.09448,0,0,1,6.09448,6.09448v35.811a6.09449,6.09449,0,0,1-6.09449,6.09449h-7.811a6.09448,6.09448,0,0,1-6.09448-6.09448v-35.811A6.09449,6.09449,0,0,1,753.16557,39.42013Z"
        fill="#d0cde1"
      />
      <circle cx="757.07108" cy="96.42013" r="18" fill="#3c669d" />
      <circle cx="757.07108" cy="96.42013" r="9" fill="#d0cde1" />
      <circle cx="757.07108" cy="96.42013" r="4" fill="#3c669d" />
    </chakra.svg>
  );
};
