import { ChakraProvider, ColorModeScript } from "@chakra-ui/react";
import React from "react";
import ReactDOM from "react-dom";
import { QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";
import customTheme from "./styles/theme";
import queryClient from "./utils/client";
import "@fontsource/poppins";

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <Router>
        <ChakraProvider theme={customTheme}>
          <ColorModeScript initialColorMode={customTheme.config.initialColorMode} />
          <App />
        </ChakraProvider>
      </Router>
      <ReactQueryDevtools />
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
