import {
  Box,
  Circle,
  Heading,
  HStack,
  Text
} from '@chakra-ui/react';

export const StatCard = (props) => {
  const {
    data: { label, count, icon },
  } = props;

  return (
    <Box
      bg="gray.100"
      px="6"
      py="4"
      shadow="base"
      rounded="lg"
    >
      <HStack justify="center">
        <Circle bg="primary" color="white" rounded="full" size="6">
          <Box as={icon} />
        </Circle>
        <Text fontWeight="bold" fontSize="lg" color='primary'>
          {label}
        </Text>
      </HStack>
      <Heading as="h4" size="lg" my="3" fontWeight="extrabold" color="secondary" textAlign="center">
        {count}
      </Heading>
    </Box>
  );
};
