import { Box } from "@chakra-ui/react";
import { useState } from "react";
import { NavBreadcrumb } from "../../../components/Common/Breadcrumb";
import ErrorBox from "../../../components/Common/ErrorBox";
import DashboardLayout from "../../../components/Layouts/DashboardLayout";
import { SocialMediaEmptyHandler } from "../../../components/SocialMediaPage/SocialMediaEmptyHandler";
import { SocialMediaHeader } from "../../../components/SocialMediaPage/SocialMediaHeader";
import { SocialMediaSearchForm } from "../../../components/SocialMediaPage/SocialMediaSearchForm";
import { SocialMediaView } from "../../../components/SocialMediaPage/SocialMediaView";
import useMutationHandler from "../../../hooks/useMutationHandler";
import { getUsefulSocialMediaSubtitles } from "../../../services/socialmedia";

const currentlinklocationdata = [
  {
    label: "Dashboard",
    isCurrentPage: false,
    canNavigate: false,
    to: "/dashboard/home",
  },
  {
    label: "Social Media",
    isCurrentPage: true,
    canNavigate: true,
    to: "/dashboard/social-media",
  },
];

const Index = () => {
  const { data, mutateAsync, isLoading, isError, error } = useMutationHandler(
    getUsefulSocialMediaSubtitles,
    []
  );
  const [isSearched, setIsSearched] = useState(false);

  const submitHandler = async (event, noOfVideos, searchQuery, movieName) => {
    event.preventDefault();
    if (!isSearched) {
      setIsSearched(true);
    }
    await mutateAsync({
      search_query: searchQuery,
      no_of_videos: noOfVideos,
      movie_name: movieName,
    });
  };

  return (
    <DashboardLayout>
      <NavBreadcrumb currentlinklocationdata={currentlinklocationdata} />
      <Box mt="10" justifySelf="center" alignItems="center">
        <Box overflowX="auto" mt="5" borderWidth="1px" borderRadius="lg" p="5">
          <SocialMediaHeader />
          <SocialMediaSearchForm
            submitHandler={submitHandler}
            isLoading={isLoading}
          />
        </Box>
      </Box>
      {!isLoading && data?.data?.length > 0 && (
        <SocialMediaView data={data?.data} />
      )}
      {isError && <ErrorBox errorMessage={error} />}
      {(data?.data.length === 0 || !isSearched) && (
        <SocialMediaEmptyHandler isSearched={isSearched} />
      )}
    </DashboardLayout>
  );
};

export default Index;
