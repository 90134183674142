import {
  Drawer,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  IconButton,
} from "@chakra-ui/react";
import { HiOutlineMenu } from "react-icons/hi";
import useMobileMenuState from "../../hooks/useMobileMenuState";
import { Logo } from "../Common/Logo";
import { Sidebar } from "./Sidebar";

export const DashbaoardMobileLayout = () => {
  const { isOpen, onClose, onOpen } = useMobileMenuState();
  return (
    <Flex
      align="center"
      justify="space-between"
      py="2"
      px="4"
      bg="gray.50"
      display={{
        base: "flex",
        md: "none",
      }}
      borderBottomWidth="1px"
    >
      <Logo h="6" boxSize={{ base: "2rem", md: "10rem" }} />
      <IconButton
        onClick={onOpen}
        variant="unstyled"
        display="flex"
        cursor="pointer"
        aria-label="Menu"
        icon={<HiOutlineMenu fontSize="1.5rem" />}
      />
      <Drawer
        size="xs"
        placement="left"
        isOpen={isOpen}
        blockScrollOnMount={false}
        onClose={onClose}
      >
        <DrawerOverlay />
        <DrawerContent bg="white" shadow="none" position="relative" maxW="72">
          <Sidebar width="full" height="full" bg="inherit" border="0" />
          <DrawerCloseButton
            bg="primary"
            _hover={{
              bg: "blue.600",
            }}
            _active={{
              bg: "primary",
            }}
            rounded="0"
            position="absolute"
            color="white"
            right="-8"
            top="0"
          />
        </DrawerContent>
      </Drawer>
    </Flex>
  );
};
