import {
  Alert,
  AlertIcon,
  Box,
  Center,
  Spinner,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Card } from "../../components/Cards/BasicCard";
import { Logo } from "../../components/Common/Logo";
import useMutationHandler from "../../hooks/useMutationHandler";
import { confirmAccountApi } from "../../services/user";
import { genericErrorMsg } from "../../utils/helper";

const ConfirmAccount = (props) => {
  let history = useHistory();
  let location = useLocation();
  const [errorMessage, setErrorMessage] = useState("");

  const { mutateAsync } = useMutationHandler(confirmAccountApi, []);

  useEffect(
    () => {
      const token = props.match.params.token;
      if (token) {
        mutateAsync(
          {
            token,
          },
          {
            onSuccess: () => {
              let { from } = location.state || {
                from: { pathname: "/login" },
              };
              history.replace(from);
            },
            onError: (err) => {
              const errorMsg = genericErrorMsg(err);
              setErrorMessage(errorMsg);
            },
          }
        );
      }
    }, // eslint-disable-next-line
    []
  );

  return (
    <Box
      as="section"
      bgGradient={{ md: "linear(to-r, blue.600, purple.600)" }}
      minH="100vh"
      py="20"
    >
      <Card maxW="2xl" mx="auto" textAlign="center">
        <Stack maxW="lg" mx="auto" spacing="8">
          <Center>
            <Logo boxSize={{ base: "8rem", md: "10rem" }} />
          </Center>
          {errorMessage && (
            <Alert status="error">
              <AlertIcon />
              {errorMessage}
            </Alert>
          )}
          <Box>
            <Spinner />
            <Text mt="5">Account confirmation in process.....</Text>
          </Box>
        </Stack>
      </Card>
    </Box>
  );
};

export default ConfirmAccount;
