import { Box, Flex, Heading } from "@chakra-ui/react";
import { useState } from "react";
import { useAsyncDebounce } from "react-table";
import { columns as SubtitleAssignmentColumns } from "../../datatables/subtitleAssignment";
import usePaginationReducer from "../../hooks/usePaginationReducer";
import useQueryHandler from "../../hooks/useQueryHandler";
import { getS3MoviesApi, searchS3MoviesApi } from "../../services/s3movies";
import queryClient from "../../utils/client";
import {
  RQ_SUBTITLE_ASSIGNMENT_TABLE_KEY,
  RQ_SUBTITLE_ASSIGNMENT_TABLE_SEARCH_KEY,
} from "../../utils/constants";
import ErrorBox from "../Common/ErrorBox";
import { CustomTable } from "../Table/CustomTable";

const SubtitleAssignmentTable = () => {
  const [searchValue, setSearchValue] = useState("");
  const [showSearchData, setShowSearchData] = useState(false);

  const {
    page: s3MoviePage,
    limit: s3MovieLimit,
    dispatch: s3MovieNotSearchDispatch,
  } = usePaginationReducer();

  const {
    page: s3SearchMoviePage,
    limit: s3SearchLimit,
    dispatch: s3SearchMovieDispatch,
  } = usePaginationReducer();

  const {
    data: s3Movie,
    isLoading: s3MovieWithoutSearchIsLoading,
    isError: isS3MovieError,
    error: s3MovieErrorMessage,
  } = useQueryHandler(
    [RQ_SUBTITLE_ASSIGNMENT_TABLE_KEY, s3MoviePage, s3MovieLimit],
    () => getS3MoviesApi(s3MoviePage, s3MovieLimit),
    {
      keepPreviousData: true,
      staleTime: Infinity,
    }
  );

  const {
    data: s3SearchMovie,
    isLoading: s3MovieSearchIsLoading,
    isError: isS3SearchMovieError,
    error: s3MovieSearchErrorMessage,
  } = useQueryHandler(
    [
      RQ_SUBTITLE_ASSIGNMENT_TABLE_SEARCH_KEY,
      searchValue,
      s3SearchMoviePage,
      s3SearchLimit,
    ],
    () => searchS3MoviesApi(searchValue, s3SearchMoviePage, s3SearchLimit)
  );

  const onS3MovieSearch = useAsyncDebounce(async (value) => {
    if (value) {
      setShowSearchData(true);
      setSearchValue(value);
      queryClient.invalidateQueries([
        RQ_SUBTITLE_ASSIGNMENT_TABLE_SEARCH_KEY,
        searchValue,
        s3SearchMoviePage,
        s3SearchLimit,
      ]);
    } else if (!value) {
      setShowSearchData(false);
    }
  }, 150);

  const s3MovieData = showSearchData ? s3SearchMovie : s3Movie;
  const s3MovieIsLoading = showSearchData
    ? s3MovieSearchIsLoading
    : s3MovieWithoutSearchIsLoading;
  const s3MovieError = showSearchData ? isS3SearchMovieError : isS3MovieError;
  const s3MovieDispatch = showSearchData
    ? s3SearchMovieDispatch
    : s3MovieNotSearchDispatch;
  const s3CurrentPage = showSearchData ? s3SearchMoviePage : s3MoviePage;
  const s3CurrentPageLimit = showSearchData ? s3SearchLimit : s3MovieLimit;
  const s3ErrorMessage = showSearchData
    ? s3MovieSearchErrorMessage
    : s3MovieErrorMessage;

  return (
    <Box overflowX="auto" mt="5" borderWidth="1px" borderRadius="lg" p="5">
      <Flex
        direction={{
          base: "column",
          lg: "row",
        }}
        mb={{
          base: "5",
          lg: "0",
        }}
        justifyContent="space-between"
      >
        <Heading size="lg" mb="6" color="primary">
          Subtitle Assignment
        </Heading>
      </Flex>
      {!s3MovieError && (
        <CustomTable
          isLoading={s3MovieIsLoading}
          tableData={s3MovieData?.data?.movies || []}
          Columns={SubtitleAssignmentColumns}
          currentPage={s3CurrentPage}
          limit={s3CurrentPageLimit}
          dispatch={s3MovieDispatch}
          totalCount={s3MovieData?.data?.total_pages || 0}
          onSearch={onS3MovieSearch}
        />
      )}
      {s3MovieError && <ErrorBox errorMessage={s3ErrorMessage} />}
    </Box>
  );
};

export default SubtitleAssignmentTable;
