import { Box } from '@chakra-ui/react';

export const ProfileCard = (props) => (
  <Box
    textAlign={{
      sm: 'center',
    }}
    pt="2"
    {...props}
  />
);
