import { extendTheme } from "@chakra-ui/react";
import { createBreakpoints } from "@chakra-ui/theme-tools";

const breakpoints = createBreakpoints({
  sm: "30em",
  md: "48em",
  lg: "62em",
  xl: "80em",
  "2xl": "96em",
});

const theme = extendTheme({
  config: {
    initialColorMode: "light",
    useSystemColorMode: false,
  },
  fonts: {
	  heading: "Poppins",
	  body: "Poppins",
	},
  colors: {
    primary: "#3C669D",
    secondary: "#43A048",
    useful: "#E8F5E9",
    not_audible: "#fff5b1",
    useless: '#ffeef0'
  },
  breakpoints,
});

export default theme;
