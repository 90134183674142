import { Box } from "@chakra-ui/react";
import { useState } from "react";
import { useAsyncDebounce } from "react-table";
import { NavBreadcrumb } from "../../../components/Common/Breadcrumb";
import ErrorBox from "../../../components/Common/ErrorBox";
import DashboardLayout from "../../../components/Layouts/DashboardLayout";
import { CustomTable } from "../../../components/Table/CustomTable";
import { columns as qualityControlColumn } from "../../../datatables/qualityControl";
import useMutationHandler from "../../../hooks/useMutationHandler";
import usePaginationReducer from "../../../hooks/usePaginationReducer";
import useQueryHandler from "../../../hooks/useQueryHandler";
import {
  getS3MoviesQcApi,
  searchS3MoviesQcApi,
  updates3MovieQcActor,
} from "../../../services/s3movies";
import queryClient from "../../../utils/client";
import {
  RQ_QC_MOVIE_SELECTED_TABLE_KEY,
  RQ_QC_MOVIE_TABLE_KEY,
  RQ_QC_MOVIE_TABLE_SEARCH_KEY,
} from "../../../utils/constants";

const currentlinklocationdata = [
  {
    label: "Dashboard",
    isCurrentPage: false,
    canNavigate: false,
    to: "/dashboard/home",
  },
  {
    label: "Qualtity Control Pool",
    isCurrentPage: true,
    canNavigate: true,
    to: "/dashboard/quality-control-pool",
  },
];

const Index = () => {
  const [searchValue, setSearchValue] = useState("");
  const [showSearchData, setShowSearchData] = useState(false);

  const {
    page: s3QcMoviePage,
    limit: s3QcMovieLimit,
    dispatch: s3QcMovieStatusNotSearchDispatch,
  } = usePaginationReducer();

  const {
    page: s3QcSearchMoviePage,
    limit: s3QcSearchLimit,
    dispatch: s3QcSearchMovieDispatch,
  } = usePaginationReducer();

  const { mutateAsync: mutateAsyncMovieQC } = useMutationHandler(
    updates3MovieQcActor,
    [RQ_QC_MOVIE_TABLE_KEY, RQ_QC_MOVIE_TABLE_SEARCH_KEY, RQ_QC_MOVIE_SELECTED_TABLE_KEY, RQ_QC_MOVIE_TABLE_SEARCH_KEY]
  );

  const {
    data: s3QcMovie,
    isLoading: s3QcMovieWithoutSearchIsLoading,
    isError: isS3QcMovieError,
    error: s3QcMovieErrorMessage,
  } = useQueryHandler(
    [RQ_QC_MOVIE_TABLE_KEY, s3QcMoviePage, s3QcMovieLimit],
    () => getS3MoviesQcApi(s3QcMoviePage, s3QcMovieLimit),
    {
      keepPreviousData: true,
      staleTime: Infinity,
    }
  );

  const {
    data: s3QcSearchMovie,
    isLoading: s3QcMovieSearchIsLoading,
    isError: isS3QcSearchMovieError,
    error: s3QcMovieSearchErrorMessage,
  } = useQueryHandler(
    [
      RQ_QC_MOVIE_TABLE_SEARCH_KEY,
      searchValue,
      s3QcSearchMoviePage,
      s3QcSearchLimit,
    ],
    () => searchS3MoviesQcApi(searchValue, s3QcSearchMoviePage, s3QcSearchLimit)
  );

  const onS3MovieSearch = useAsyncDebounce(async (value) => {
    if (value) {
      setShowSearchData(true);
      setSearchValue(value);
      queryClient.invalidateQueries([
        RQ_QC_MOVIE_TABLE_SEARCH_KEY,
        searchValue,
        s3QcSearchMoviePage,
        s3QcSearchLimit,
      ]);
    } else if (!value) {
      setShowSearchData(false);
    }
  }, 150);

  const s3MovieData = showSearchData ? s3QcSearchMovie : s3QcMovie;
  const s3MovieIsLoading = showSearchData
    ? s3QcMovieSearchIsLoading
    : s3QcMovieWithoutSearchIsLoading;
  const s3MovieError = showSearchData
    ? isS3QcSearchMovieError
    : isS3QcMovieError;
  const s3MovieDispatch = showSearchData
    ? s3QcSearchMovieDispatch
    : s3QcMovieStatusNotSearchDispatch;
  const s3CurrentPage = showSearchData ? s3QcSearchMoviePage : s3QcMoviePage;
  const s3CurrentPageLimit = showSearchData ? s3QcSearchLimit : s3QcMovieLimit;
  const s3ErrorMessage = showSearchData
    ? s3QcMovieSearchErrorMessage
    : s3QcMovieErrorMessage;

  const mutateMovieQcStatus = async ({ id }) => {
    await mutateAsyncMovieQC({ id });
  };

  return (
    <DashboardLayout>
      <NavBreadcrumb currentlinklocationdata={currentlinklocationdata} />
      <Box mt="10">
        {!s3MovieError && (
          <CustomTable
            isLoading={s3MovieIsLoading}
            Columns={qualityControlColumn}
            currentPage={s3CurrentPage}
            dispatch={s3MovieDispatch}
            limit={s3CurrentPageLimit}
            onSearch={onS3MovieSearch}
            tableData={s3MovieData?.data?.movies || []}
            totalCount={s3MovieData?.data?.total_pages || 0}
            mutateFnObjects={{ mutateMovieQcStatus }}
          />
        )}
        {s3MovieError && <ErrorBox errorMessage={s3ErrorMessage} />}
      </Box>
    </DashboardLayout>
  );
};

export default Index;
