import { Flex, Heading } from "@chakra-ui/react";

export const SocialMediaHeader = () => {
  return (
    <Flex
      direction={{
        base: "column",
        lg: "row",
      }}
      mb={{
        base: "5",
        lg: "0",
      }}
      justifyContent="space-between"
    >
      <Heading size="lg" mb="6" color="primary">
        Get Videos
      </Heading>
    </Flex>
  );
};

