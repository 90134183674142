import { SearchIcon } from "@chakra-ui/icons";
import {
  Button,
  Center,
  Input,
  InputGroup,
  InputLeftElement,
  Stack,
} from "@chakra-ui/react";
import { useState } from "react";
import { AiOutlineNumber } from "react-icons/ai";
import { BiMovie } from "react-icons/bi";
import { MdTextFields } from "react-icons/md";

export const SocialMediaSearchForm = (props) => {
  const [noOfVideos, setnoOfVideos] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [movieName, setMovieName] = useState("");

  const { submitHandler, isLoading } = props;

  return (
    <form
      onSubmit={(event) =>
        submitHandler(event, noOfVideos, searchQuery, movieName)
      }
    >
      <Stack spacing="6" direction={{ base: "column", xl: "row" }}>
        <InputGroup width={{ base: "100%", xl: "50%" }} size="lg">
          <InputLeftElement
            pointerEvents="none"
            children={<AiOutlineNumber />}
          />
          <Input
            placeholder="Number of Videos"
            type="number"
            value={noOfVideos}
            onChange={(event) => setnoOfVideos(event.target.value)}
            min={1}
            max={10}
          />
        </InputGroup>
        <InputGroup size="lg">
          <InputLeftElement pointerEvents="none" children={<BiMovie />} />
          <Input
            placeholder="Movie Name"
            onChange={(event) => setMovieName(event.target.value)}
            value={movieName}
          />
        </InputGroup>
        <InputGroup size="lg">
          <InputLeftElement pointerEvents="none" children={<MdTextFields />} />
          <Input
            placeholder="Video Text"
            onChange={(event) => setSearchQuery(event.target.value)}
            value={searchQuery}
          />
        </InputGroup>
      </Stack>
      <Center mt="5">
        <Button
          leftIcon={<SearchIcon />}
          bg="primary"
          color="white"
          type="submit"
          isLoading={isLoading}
        >
          Search
        </Button>
      </Center>
    </form>
  );
};
