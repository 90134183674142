import { Alert, AlertIcon, AlertTitle, Box } from "@chakra-ui/react";
import { genericErrorMsg } from "../../utils/helper";

const ErrorBox = (props) => {
  const { errorMessage, ...rest } = props;
  
  return (
    <Box {...rest}>
      <Alert
        status="error"
        variant="subtle"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        height={{
          base: "150px",
          "2xl": "180px",
        }}
        borderRadius="lg"
      >
        <AlertIcon boxSize={{ base: "50px", "2xl": "60px" }} mr={0} />
        <AlertTitle mt={4} mb={1} fontSize={{ base: "lg", "2xl": "xl" }}>
          {genericErrorMsg(errorMessage)}
        </AlertTitle>
      </Alert>
    </Box>
  );
};

export default ErrorBox;
