import { Box, HStack, Icon, Text } from "@chakra-ui/react";
import { Link, useHistory } from "react-router-dom";
import { isAllowed as isAllowedFn } from "../../utils/helper";

export const NavLink = (props) => {
  const { icon, label, pathname, accessLevel, userAccess, ...rest } = props;
  const history = useHistory();

  const isActive = () => {
    const location = history.location.pathname;
    return location.startsWith(pathname) ? "primary" : "";
  };

  const isAllowed = isAllowedFn(accessLevel, userAccess);

  return (
    <>
      {isAllowed ? (
        <Link to={pathname}>
          <Box
            display="block"
            py="2"
            px="3"
            borderRadius="md"
            transition="all 0.3s"
            fontWeight="medium"
            fontSize="md"
            bg={isActive()}
            color={isActive() ? "white" : "gray.700"}
            {...rest}
          >
            <HStack spacing="4">
              <Icon as={icon} fontSize="lg" opacity={0.64} />
              <Text as="span">{label}</Text>
            </HStack>
          </Box>
        </Link>
      ) : (
        <></>
      )}
    </>
  );
};
