import { HStack, Icon, Stack, Text } from '@chakra-ui/react';
import { GiJeweledChalice, GiLightBulb } from 'react-icons/gi';
import { HiLink } from 'react-icons/hi';
import { MdTextFields } from 'react-icons/md';

export const UserInfoCard = (props) => {
  const { power, challenges, hints, difficult, ...stackProps } = props;
  return (
    <Stack
      direction={{
        base: 'column',
        sm: 'row',
      }}
      spacing={{
        base: '2',
        sm: '6',
      }}
      mt="4"
      fontSize="md"
      fontWeight="medium"
      color="gray.500"
      {...stackProps}
    >
      <HStack>
        <Icon as={GiJeweledChalice} fontSize="1.3rem" color="white" />
        <Text color="white">Power: {power}</Text>
      </HStack>
      <HStack>
        <Icon as={HiLink} fontSize="1.3rem" color="white" />
        <Text color="white">Challenges: {challenges}</Text>
      </HStack>
      <HStack>
        <Icon color="white" as={GiLightBulb} fontSize="1.3rem" />
        <Text color="white">Hints: {hints}</Text>
      </HStack>
      <HStack>
        <Icon as={MdTextFields} fontSize="1.3rem" color="white" />
        <Text color="white">Difficult: {difficult}</Text>
      </HStack>
    </Stack>
  );
};
