import { AddIcon } from "@chakra-ui/icons";
import { Button, Text } from "@chakra-ui/react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { UnderlineLink } from "../components/Common/UnderlineLink";
import queryClient from "../utils/client";
import { RQ_USER_KEY } from "../utils/constants";

export const columns = [
  {
    Header: "Movie Name",
    accessor: (d) => {
      const userAccess = queryClient.getQueryData(RQ_USER_KEY)?.data?.access;
      if (userAccess.includes("admin") || userAccess.includes("superadmin")) {
        return (
          d.movie_file_name && (
            <Link
              to={`/dashboard/quality-control-pool/movies/${d._id}/${d.movie_file_name}`}
            >
              <UnderlineLink fontSize="md" color="secondary" p="2">
                {d.movie_file_name}
              </UnderlineLink>
            </Link>
          )
        );
      } else {
        return (
          <Text fontSize="md" color="secondary" p="2">
            {d.movie_file_name}
          </Text>
        );
      }
    },
  },
  {
    Header: "Progress",
    accessor: (d) => d.progress && `${d.progress}%`,
    disableFilters: true,
  },
  {
    Header: "Assignee",
    accessor: "actor.qc",
    disableFilters: true,
  },
  {
    Header: "Action",
    accessor: "action",
    Cell: function (rows) {
      const [isLoading, setIsLoading] = useState(false);
      const original = rows.row.original;
      const fn = rows.mutateFnObjects.mutateMovieQcStatus;
      let exists = false;
      if (original?.actor) {
        exists = "qc" in original?.actor;
      }
      return (
        <>
          {!exists && (
            <Button
              isLoading={isLoading}
              onClick={async () => {
                setIsLoading(true);
                await fn({ id: original._id });
                setIsLoading(false);
              }}
              color="primary"
              variant="outline"
              leftIcon={<AddIcon />}
            >
              Select
            </Button>
          )}
        </>
      );
    },
  },
];
