import { Button } from "@chakra-ui/react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { CustomInput } from "../FormControls/CustomInput";

const ForgetPasswordSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Required"),
});

const ForgetPasswordForm = ({ initialValues, handleSubmit, isLoading }) => {
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={ForgetPasswordSchema}
    >
      {(_props) => (
        <Form>
          <CustomInput
            placeholder="Enter email address"
            label="Email Address"
            name="email"
            isRequired={true}
          />

          <Button
            mt="3"
            isFullWidth
            fontSize="md"
            fontWeight="bold"
            bgColor="primary"
            color="white"
            isLoading={isLoading}
            type="submit"
          >
            Forget Password
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default ForgetPasswordForm;
