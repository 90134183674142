import { useEffect } from "react";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import useQueryHandler from "./hooks/useQueryHandler";
import webRoutes from "./routes";
import { meApi } from "./services/user";
import { RQ_USER_KEY } from "./utils/constants";

function App() {
  const history = useHistory();
  const location = useLocation();

  const { data, isSuccess, isLoading, error } = useQueryHandler(
    RQ_USER_KEY,
    meApi
  );

  useEffect(() => {
    if (!isLoading && error && !data) {
      if (
        location.pathname !== "/login" &&
        location.pathname !== "/register" &&
        location.pathname !== "/forget-password" &&
        !location.pathname.startsWith("/reset-password") &&
        !location.pathname.startsWith('/telegram/confirm')
      ) {
        history.replace("/login");
      }
    }
  }, [data, error, isLoading, isSuccess, history, location.pathname]);

  return (
    <Switch>
      {webRoutes.map((route, index) => (
        <Route {...route} key={index} />
      ))}
    </Switch>
  );
}

export default App;
