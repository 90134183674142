import invokeApi from "../utils/api";
import { $axiosV1 } from "../utils/axios";

export const registerApi = async (data) => {
  try {
    const resp = await invokeApi($axiosV1, "/user/register", "POST", data);
    return resp;
  } catch (error) {
    throw error;
  }
};

export const telegramPasswordUpdateApi = async (data) => {
  try {
    const resp = await invokeApi($axiosV1, "/user/telegram/password/update", "POST", data);
    return resp;
  } catch (error) {
    throw error;
  }
};


export const loginApi = async (data) => {
  try {
    const resp = await invokeApi($axiosV1, "/user/login", "POST", data);
    return resp;
  } catch (error) {
    throw error;
  }
};

export const confirmAccountApi = async (data) => {
  try {
    const resp = await invokeApi($axiosV1, `/user/confirm/${data.token}`, "PUT", data);
    return resp;
  } catch (error) {
    throw error;
  }
};

export const forgetPasswordApi = async (data) => {
  try {
    const resp = await invokeApi($axiosV1, "/user/forget-password", "PUT", data);
    return resp;
  } catch (error) {
    throw error;
  }
};

export const resetPasswordApi = async (data) => {
  try {
    const resp = await invokeApi($axiosV1, `/user/reset-password/${data.token}`, "PUT", data);
    return resp;
  } catch (error) {
    throw error;
  }
};

export const meApi = async () => {
  try {
    const resp = await invokeApi($axiosV1, "/user/me");
    return resp;
  } catch (error) {
    throw error;
  }
};

export const updateUserProfileApi = async (data) => {
  try {
    const resp = await invokeApi($axiosV1, "/user/update", 'PUT', data);
    return resp;
  } catch (error) {
    throw error;
  }
};

export const logoutApi = async () => {
  try {
    const resp = await invokeApi($axiosV1, "/user/logout", "PUT");
    return resp;
  } catch (error) {
    throw error;
  }
};
