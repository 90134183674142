import {
  Box,
  Flex,
  Select,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useMediaQuery,
} from "@chakra-ui/react";
import { useEffect, useMemo } from "react";
import {
  useFilters,
  useGlobalFilter,
  usePagination,
  useTable,
} from "react-table";
import { PAGE_CHANGED, PAGE_SIZE_CHANGED } from "../../utils/constants";
import CustomSpinner from "../Common/CustomSpinner";
import { CustomGlobalFilter } from "./CustomGlobalFilter";
import { CustomPaginationBox } from "./CustomPaginationBox";

export const CustomTable = ({
  isLoading,
  Columns,
  tableData,
  limit,
  currentPage,
  dispatch,
  totalCount,
  onSearch,
  mutateFnObjects = {},
}) => {
  const [isTablet] = useMediaQuery("(max-width: 1200px)");
  const columns = useMemo(() => Columns, [Columns]);
  const data = useMemo(() => tableData, [tableData]);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: currentPage,
        pageSize: limit,
      },
      manualPagination: true,
      pageCount: totalCount,
    },
    useFilters,
    useGlobalFilter,
    usePagination
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    state,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    pageCount,
    setPageSize,
  } = tableInstance;

  const { pageIndex, pageSize } = state;

  useEffect(() => {
    dispatch({ type: PAGE_CHANGED, payload: pageIndex });
  }, [pageIndex, dispatch]);

  useEffect(() => {
    dispatch({ type: PAGE_SIZE_CHANGED, payload: pageSize });
    gotoPage(0);
  }, [pageSize, gotoPage, dispatch]);

  return (
    <Box>
      <CustomGlobalFilter onSearch={onSearch} />
      <Select
        value={pageSize}
        onChange={(e) => setPageSize(e.target.value)}
        size="md"
        variant="outline"
      >
        {[10, 25, 50].map((pageSize) => (
          <option key={pageSize} value={pageSize}>
            Show {pageSize}
          </option>
        ))}
      </Select>
      {isLoading ? (
        <CustomSpinner height="20vh" />
      ) : (
        <Table {...getTableProps()} my="8" borderWidth="1px" fontSize="sm">
          <Thead bg="gray.50">
            {headerGroups.map((headerGroup) => (
              <Tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <Th
                    {...column.getHeaderProps()}
                    whiteSpace="nowrap"
                    scope="col"
                  >
                    {column.render("Header")}
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <Tr
                  {...row.getRowProps()}
                  _even={{
                    bg: "gray.50",
                  }}
                >
                  {row.cells.map((cell) => (
                    <Td {...cell.getCellProps()} whiteSpace="nowrap">
                      {cell.render("Cell", { mutateFnObjects })}
                    </Td>
                  ))}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      )}
      <Flex justifyContent={isTablet ? "flex-start" : "center"}>
        {!isLoading && (
          <CustomPaginationBox
            previousPage={previousPage}
            canPreviousPage={canPreviousPage}
            nextPage={nextPage}
            canNextPage={canNextPage}
            gotoPage={gotoPage}
            pageCount={pageCount}
            pageIndex={pageIndex}
            pageOptions={pageOptions}
          />
        )}
      </Flex>
    </Box>
  );
};
