import { Image } from '@chakra-ui/react';

export const Logo = (props) => {
  return (
    <Image
      src="/oolingo-logo.png"
      objectFit="contain"
      alt="oolingo-logo.png"
      {...props}
    />
  );
};
